import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CookieConsent from "../components/CookiesConcent";
export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  })
  return (
    <div className="min-h-screen bg-[#2559EC] text-white text-left">
      <Navbar />
      {/* Main content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-5xl font-bold mb-8">Privacy Policy</h1>

        <div className="space-y-8">
          {/* Policy scope */}
          <div>
            <p className="text-lg font-bold">
              This policy applies to all SyncTank's products, apps and websites
              by default, except for the ones mentioned below, which have their
              own policies as provided in the accompanying links:
            </p>
            <p className="mt-4 opacity-85">
              – TweetWizard's (
              <a href="#" className="text-blue-400 hover:underline">
                Privacy Policy
              </a>
              )
            </p>
          </div>

          {/* Last updated */}
          <div>
            <p className="text-base font-bold">Last updated August 27, 2024</p>
          </div>

          {/* Privacy Notice */}
          <div className="space-y-4">
            <p className="my-2">
              This Privacy Notice for SyncTank AI Technologies Inc. and its
              affiliated companies (doing business as SyncTank) ("we," "us," or
              "our"), describes how and why we might access, collect, store,
              use, and/or share ("process") your personal information when you
              use our services ("Services"), including when you:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                Visit our website at https://synctankai.com, or any website of
                ours that links to this Privacy Notice
              </li>
              <li>
                Download and use our mobile application (Fluffy), or any other
                application of ours that links to this Privacy Notice
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
          </div>

          {/* Questions section */}
          <div>
            <p className="my-2">
              Questions or concerns? Reading this Privacy Notice will help you
              understand your privacy rights and choices. We are responsible for
              making decisions about how your personal information is processed.
              If you do not agree with our policies and practices, please do not
              use our Services. If you still have any questions or concerns,
              please contact us at contact@synctankai.com.
            </p>
          </div>

          {/* Summary section */}
          <div className="mt-12">
            <h2 className="text-3xl font-bold mb-6">SUMMARY OF KEY POINTS</h2>
            <p className="italic mb-4 font-bold">
              This summary provides key points from our Privacy Notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our{" "}
              <a href="#" className="text-blue-400 hover:underline">
                table of contents
              </a>{" "}
              below to find the section you are looking for.
            </p>
            <div className="space-y-4 font-bold">
              <p className="my-2">
                What personal information do we process? When you visit, use, or
                navigate our Services, we may process personal information
                depending on how you interact with us and the Services, the
                choices you make, and the products and features you use. Learn
                more about{" "}
                <a href="#" className="text-blue-400 hover:underline">
                  personal information you disclose to us
                </a>
                .
              </p>
              <p className="my-2">
                Do we process any sensitive personal information? Some of the
                information may be considered "special" or "sensitive" in
                certain jurisdictions, for example, racial or ethnic origins,
                sexual orientation, and religious beliefs. We do not process
                sensitive personal information.
              </p>
              <p className="my-2">
                Do we process any sensitive personal information? Some of the
                information may be considered "special" or "sensitive" in
                certain jurisdictions, for example, racial or ethnic origins,
                sexual orientation, and religious beliefs. We do not process
                sensitive personal information.
              </p>
              <p className="my-2">
                Do we collect any information from third parties? We may collect
                information from public databases, marketing partners, social
                media platforms, and other outside sources. Learn more about
                information collected from other sources.
              </p>
              <p className="my-2">
                Do we collect any information from third parties? We may collect
                information from public databases, marketing partners, social
                media platforms, and other outside sources. Learn more about
                information collected from other sources.
              </p>

              <p className="my-2">
                How do we process your information? We process your information
                to provide, improve, and administer our Services, communicate
                with you, for security and fraud prevention, and to comply with
                law. We may also process your information for other purposes
                with your consent. We process your information only when we have
                a valid legal reason to do so. Learn more about how we process
                your information.
              </p>
              <p className="my-2">
                In what situations and with which parties do we share personal
                information? We may share information in specific situations and
                with specific third parties. Learn more about when and with whom
                we share your personal information.
              </p>
              <p className="my-2">
                How do we keep your information safe? We have adequate
                organizational and technical processes and procedures in place
                to protect your personal information. However, no electronic
                transmission over the internet or information storage technology
                can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorized
                third parties will not be able to defeat our security and
                improperly collect, access, steal, or modify your information.
                Learn more about how we keep your information safe.
              </p>
              <p className="my-2">
                What are your rights? Depending on where you are located
                geographically, the applicable privacy law may mean you have
                certain rights regarding your personal information. Learn more
                about your privacy rights.
              </p>
              <p className="my-2">
                How do you exercise your rights? The easiest way to exercise
                your rights is by contacting us. We will consider and act upon
                any request in accordance with applicable data protection laws.
              </p>
              <p className="my-2">
                Want to learn more about what we do with any information we
                collect? Review the Privacy Notice in full.
              </p>

              <section className="mb-16">
                <h2 className="text-4xl font-bold mb-6">TABLE OF CONTENTS</h2>
                <ol className="list-decimal list-inside space-y-2">
                  <li>
                    <a href="#collect" className="hover:text-blue-400">
                      WHAT INFORMATION DO WE COLLECT?
                    </a>
                  </li>
                  <li>
                    <a href="#process" className="hover:text-blue-400">
                      HOW DO WE PROCESS YOUR INFORMATION?
                    </a>
                  </li>
                  <li>
                    <a href="#legal-bases" className="hover:text-blue-400">
                      WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                      INFORMATION?
                    </a>
                  </li>
                  <li>
                    <a href="#share" className="hover:text-blue-400">
                      WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </a>
                  </li>
                  <li>
                    <a href="#cookies" className="hover:text-blue-400">
                      DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </a>
                  </li>
                  <li>
                    <a href="#ai" className="hover:text-blue-400">
                      DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?
                    </a>
                  </li>
                  <li>
                    <a href="#social" className="hover:text-blue-400">
                      HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </a>
                  </li>
                  <li>
                    <a href="#retention" className="hover:text-blue-400">
                      HOW LONG DO WE KEEP YOUR INFORMATION?
                    </a>
                  </li>
                  <li>
                    <a href="#safety" className="hover:text-blue-400">
                      HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </a>
                  </li>
                  <li>
                    <a href="#minors" className="hover:text-blue-400">
                      DO WE COLLECT INFORMATION FROM MINORS?
                    </a>
                  </li>
                  <li>
                    <a href="#rights" className="hover:text-blue-400">
                      WHAT ARE YOUR PRIVACY RIGHTS?
                    </a>
                  </li>
                  <li>
                    <a href="#do-not-track" className="hover:text-blue-400">
                      CONTROLS FOR DO-NOT-TRACK FEATURES
                    </a>
                  </li>
                  <li>
                    <a href="#us-rights" className="hover:text-blue-400">
                      DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </a>
                  </li>
                  <li>
                    <a href="#other-rights" className="hover:text-blue-400">
                      DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
                    </a>
                  </li>
                  <li>
                    <a href="#updates" className="hover:text-blue-400">
                      DO WE MAKE UPDATES TO THIS NOTICE?
                    </a>
                  </li>
                  <li>
                    <a href="#contact" className="hover:text-blue-400">
                      HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </a>
                  </li>
                  <li>
                    <a href="#review" className="hover:text-blue-400">
                      HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                      FROM YOU?
                    </a>
                  </li>
                </ol>
              </section>

              <section id="collect" className="mb-16">
                <h2 className="text-4xl font-bold mb-6">
                  1. WHAT INFORMATION DO WE COLLECT?
                </h2>
                <div className="space-y-3">
                  <h3 className="text-base font-semibold mb-2">
                    Personal Information you disclose to us
                  </h3>
                  <div className="bg-opacity-20 bg-gray-800">
                    <em className="text-gray-300">
                      In Short: We collect personal information that you provide
                      to us.
                    </em>
                  </div>

                  <p className="mb-4">
                    We collect personal information that you voluntarily provide
                    to us when you register on the Services, express an interest
                    in obtaining information about us or our products and
                    Services, when you participate in activities on the
                    Services, or otherwise when you contact us.
                  </p>

                  <h4 className="text-lg mb-2">
                    Personal Information Provided by You
                  </h4>
                  <ul className="list-disc pl-6 space-y-2 font-medium">
                    <li>names</li>
                    <li>usernames</li>
                    <li>email addresses</li>
                    <li>contact or authentication data</li>
                    <li>billing addresses</li>
                    <li>personal information</li>
                  </ul>

                  <p className="my-2">
                    Sensitive Information. We do not process sensitive
                    information.
                  </p>
                  <p className="my-2">
                    Payment Data. We may collect data necessary to process your
                    payment if you choose to make purchases, such as your
                    payment instrument number, and the security code associated
                    with your payment instrument. All payment data is handled
                    and stored by Stripe. You may find their privacy notice
                    link(s) here: https://stripe.com/privacy.
                  </p>

                  <p className="my-2">
                    Social Media Login Data. We may provide you with the option
                    to register with us using your existing social media account
                    details, like your Facebook, X, or other social media
                    account. If you choose to register in this way, we will
                    collect certain profile information about you from the
                    social media provider, as described in the section called
                    “HOW DO WE HANDLE YOUR SOCIAL LOGINS?” below.
                  </p>

                  <p className="my-2">
                    All personal information that you provide to us must be
                    true, complete, and accurate, and you must notify us of any
                    changes to such personal information.
                  </p>

                  <p className="my-2">Information automatically collected</p>
                  <p className="my-2">
                    In Short: Some information — such as your Internet Protocol
                    (IP) address and/or browser and device characteristics — is
                    collected automatically when you visit our Services.
                  </p>
                  <p className="my-2">
                    We automatically collect certain information when you visit,
                    use, or navigate the Services. This information does not
                    reveal your specific identity (like your name or contact
                    information) but may include device and usage information,
                    such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs,
                    device name, country, location, information about how and
                    when you use our Services, and other technical information.
                    This information is primarily needed to maintain the
                    security and operation of our Services, and for our internal
                    analytics and reporting purposes.
                  </p>
                  <p className="my-2">
                    Like many businesses, we also collect information through
                    cookies and similar technologies. You can find out more
                    about this in our Cookie Notice.
                  </p>
                  <p className="my-2">The information we collect includes:</p>
                  <ul className="list-disc pl-6 space-y-2 font-medium">
                    <li>
                      Log and Usage Data. Log and usage data is service-related,
                      diagnostic, usage, and performance information our servers
                      automatically collect when you access or use our Services
                      and which we record in log files. Depending on how you
                      interact with us, this log data may include your IP
                      address, device information, browser type, and settings
                      and information about your activity in the Services (such
                      as the date/time stamps associated with your usage, pages
                      and files viewed, searches, and other actions you take
                      such as which features you use), device event information
                      (such as system activity, error reports (sometimes called
                      “crash dumps”), and hardware settings).
                    </li>
                    <li>
                      Device Data. We collect device data such as information
                      about your computer, phone, tablet, or other device you
                      use to access the Services. Depending on the device used,
                      this device data may include information such as your IP
                      address (or proxy server), device and application
                      identification numbers, location, browser type, hardware
                      model, Internet service provider and/or mobile carrier,
                      operating system, and system configuration information.
                    </li>
                    <li>
                      Location Data. We collect location data such as
                      information about your device’s location, which can be
                      either precise or imprecise. How much information we
                      collect depends on the type and settings of the device you
                      use to access the Services. For example, we may use GPS
                      and other technologies to collect geolocation data that
                      tells us your current location (based on your IP address).
                      You can opt out of allowing us to collect this information
                      either by refusing access to the information or by
                      disabling your Location setting on your device. However,
                      if you choose to opt out, you may not be able to use
                      certain aspects of the Services.
                    </li>
                  </ul>

                  <p className="my-2">Google API</p>

                  <p className="my-2">
                    Our use of information received from Google APIs will adhere
                    to Google API Services User Data Policy, including the
                    Limited Use requirements.
                  </p>
                  <p className="my-2">Information collected from other sources</p>
                  <p className="my-2">
                    In Short: We may collect limited data from public databases,
                    marketing partners, social media platforms, and other
                    outside sources.
                  </p>
                  <p className="my-2">
                    In order to enhance our ability to provide relevant
                    marketing, offers, and services to you and update our
                    records, we may obtain information about you from other
                    sources, such as public databases, joint marketing partners,
                    affiliate programs, data providers, social media platforms,
                    and from other third parties. This information includes
                    mailing addresses, job titles, email addresses, phone
                    numbers, intent data (or user behavior data), Internet
                    Protocol (IP) addresses, social media profiles, social media
                    URLs, and custom profiles, for purposes of targeted
                    advertising and event promotion.
                  </p>
                  <p className="my-2">
                    If you interact with us on a social media platform using
                    your social media account (e.g., Facebook or X), we receive
                    personal information about you from such platforms such as
                    your name, email address, and gender. You may have the right
                    to withdraw your consent to processing your personal
                    information. Learn more about withdrawing your consent. Any
                    personal information that we collect from your social media
                    account depends on your social media account’s privacy
                    settings. Please note that their own use of your information
                    is not governed by this Privacy Notice.
                  </p>
                </div>
              </section>

              {/* Information Processing Section */}
              <section id="process" className="mb-16">
                <h2 className="text-4xl font-semibold mb-6">
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                </h2>
                <div className="space-y-6">
                  <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                    <em className="text-gray-300">
                      In Short: We process your information to provide, improve,
                      and administer our Services, communicate with you, for
                      security and fraud prevention, and to comply with law. We
                      may also process your information for other purposes with
                      your consent.
                    </em>
                  </div>
                  <p className="my-2">
                    We process your personal information for a variety of
                    reasons, depending on how you interact with our Services,
                    including:
                  </p>
                  <ul className="list-disc  pl-6 space-y-4">
                    <li>
                      To facilitate account creation and authentication and
                      otherwise manage user accounts. We may process your
                      information so you can create and log in to your account,
                      as well as keep your account in working order.
                    </li>
                    <li>
                      To deliver and facilitate delivery of services to the
                      user. We may process your information to provide you with
                      the requested service.
                    </li>
                    <li>
                      To respond to user inquiries/offer support to users. We
                      may process your information to respond to your inquiries
                      and solve any potential issues you might have with the
                      requested service.
                    </li>
                    <li>
                      To send administrative information to you. We may process
                      your information to send you details about our products
                      and services, changes to our terms and policies, and other
                      similar information.
                    </li>
                    <li>
                      To request feedback. We may process your information when
                      necessary to request feedback and to contact you about
                      your use of our Services.
                    </li>
                    <li>
                      To send you marketing and promotional communications. We
                      may process the personal information you send to us for
                      our marketing purposes, if this is in accordance with your
                      marketing preferences. You can opt out of our marketing
                      emails at any time. For more information, see “WHAT ARE
                      YOUR PRIVACY RIGHTS?” below.
                    </li>
                    <li>
                      To deliver targeted advertising to you. We may process
                      your information to develop and display personalized
                      content and advertising tailored to your interests,
                      location, and more. For more information see our Cookie
                      Notice.
                    </li>
                    <li>
                      To protect our Services. We may process your information
                      as part of our efforts to keep our Services safe and
                      secure, including fraud monitoring and prevention.
                    </li>
                    <li>
                      To identify usage trends. We may process information about
                      how you use our Services to better understand how they are
                      being used so we can improve them.
                    </li>
                    <li>
                      To determine the effectiveness of our marketing and
                      promotional campaigns. We may process your information to
                      better understand how to provide marketing and promotional
                      campaigns that are most relevant to you.
                    </li>
                    <li>
                      To save or protect an individual’s vital interest. We may
                      process your information when necessary to save or protect
                      an individual’s vital interest, such as to prevent harm.
                    </li>
                  </ul>
                </div>
              </section>

              <section className="">
                <h2 className="text-4xl font-semibold mb-6">
                  3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                </h2>
                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <em className="text-gray-300">
                    In Short: We only process your personal information when we
                    believe it is necessary and we have a valid legal reason
                    (i.e., legal basis) to do so under applicable law, like with
                    your consent, to comply with laws, to provide you with
                    services to enter into or fulfill our contractual
                    obligations, to protect your rights, or to fulfill our
                    legitimate business interests.
                  </em>
                  <em className="text-gray-300">
                    If you are located in the EU or UK, this section applies to
                    you.
                  </em>
                  <em className="text-gray-300">
                    The General Data Protection Regulation (GDPR) and UK GDPR
                    require us to explain the valid legal bases we rely on in
                    order to process your personal information. As such, we may
                    rely on the following legal bases to process your personal
                    information:
                  </em>
                </div>
                <ul className="list-disc  pl-6 space-y-4">
                  <li>
                    Consent. We may process your information if you have given
                    us permission (i.e., consent) to use your personal
                    information for a specific purpose. You can withdraw your
                    consent at any time. Learn more about withdrawing your
                    consent.
                  </li>
                  <li>
                    Performance of a Contract. We may process your personal
                    information when we believe it is necessary to fulfill our
                    contractual obligations to you, including providing our
                    Services or at your request prior to entering into a
                    contract with you.
                  </li>
                  <li>
                    Legitimate Interests. We may process your information when
                    we believe it is reasonably necessary to achieve our
                    legitimate business interests and those interests do not
                    outweigh your interests and fundamental rights and freedoms.
                    For example, we may process your personal information for
                    some of the purposes described in order to:
                  </li>
                  <li>
                    Send users information about special offers and discounts on
                    our products and services
                  </li>
                  <li>
                    Develop and display personalized and relevant advertising
                    content for our users
                  </li>
                  <li>
                    Analyze how our Services are used so we can improve them to
                    engage and retain users
                  </li>
                  <li>Support our marketing activities</li>
                  <li>
                    Diagnose problems and/or prevent fraudulent activities
                  </li>
                  <li>
                    Understand how our users use our products and services so we
                    can improve user experience
                  </li>
                  <li>
                    To improve the quality and relevance of generated content
                    and services
                  </li>
                  <li>
                    Legal Obligations. We may process your information where we
                    believe it is necessary for compliance with our legal
                    obligations, such as to cooperate with a law enforcement
                    body or regulatory agency, exercise or defend our legal
                    rights, or disclose your information as evidence in
                    litigation in which we are involved.
                  </li>
                  <li>
                    Vital Interests. We may process your information where we
                    believe it is necessary to protect your vital interests or
                    the vital interests of a third party, such as situations
                    involving potential threats to the safety of any person.
                  </li>
                </ul>

                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <p className="text-gray-300 mt-4 mb-2">
                    If you are located in Canada, this section applies to you.
                  </p>
                  <p className="text-gray-300">
                    We may process your information if you have given us
                    specific permission (i.e., express consent) to use your
                    personal information for a specific purpose, or in
                    situations where your permission can be inferred (i.e.,
                    implied consent). You can
                    <a href="">withdraw your consent</a> at any time.
                  </p>
                  <p className="text-gray-300">
                    In some exceptional cases, we may be legally permitted under
                    applicable law to process your information without your
                    consent, including, for example:
                  </p>
                </div>
                <ul className="list-disc  pl-6 space-y-4">
                  <li>
                    If collection is clearly in the interests of an individual
                    and consent cannot be obtained in a timely way
                  </li>
                  <li>For investigations and fraud detection and prevention</li>
                  <li>
                    For business transactions provided certain conditions are
                    met
                  </li>
                  <li>
                    If it is contained in a witness statement and the collection
                    is necessary to assess, process, or settle an insurance
                    claim
                  </li>
                  <li>
                    For identifying injured, ill, or deceased persons and
                    communicating with next of kin
                  </li>
                  <li>
                    If we have reasonable grounds to believe an individual has
                    been, is, or may be victim of financial abuse
                  </li>
                  <li>
                    If it is reasonable to expect collection and use with
                    consent would compromise the availability or the accuracy of
                    the information and the collection is reasonable for
                    purposes related to investigating a breach of an agreement
                    or a contravention of the laws of Canada or a province
                  </li>
                  <li>
                    If disclosure is required to comply with a subpoena,
                    warrant, court order, or rules of the court relating to the
                    production of records
                  </li>
                  <li>
                    If it was produced by an individual in the course of their
                    employment, business, or profession and the collection is
                    consistent with the purposes for which the information was
                    produced
                  </li>
                  <li>
                    If the collection is solely for journalistic, artistic, or
                    literary purposes
                  </li>
                  <li>
                    If the information is publicly available and is specified by
                    the regulations
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </h2>
                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <em className="text-gray-300">
                    In Short: We may share information in specific situations
                    described in this section and/or with the following third
                    parties.
                  </em>
                  <p className="text-gray-300">
                    We may need to share your personal information in the
                    following situations:
                  </p>
                </div>
                <ul className="list-disc  pl-6 space-y-4">
                  <li>
                    Business Transfers. We may share or transfer your
                    information in connection with, or during negotiations of,
                    any merger, sale of company assets, financing, or
                    acquisition of all or a portion of our business to another
                    company.
                  </li>
                  <li>
                    Affiliates. We may share your information with our
                    affiliates, in which case we will require those affiliates
                    to honor this Privacy Notice. Affiliates include our parent
                    company and any subsidiaries, joint venture partners, or
                    other companies that we control or that are under common
                    control with us.
                  </li>
                  <li>
                    Business Partners. We may share your information with our
                    business partners to offer you certain products, services,
                    or promotions.
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </h2>
                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <p className="text-gray-300 mb-2">
                    We may use cookies and similar tracking technologies (like
                    web beacons and pixels) to gather information when you
                    interact with our Services. Some online tracking
                    technologies help us maintain the security of our Services
                    and your account, prevent crashes, fix bugs, save your
                    preferences, and assist with basic site functions.
                  </p>
                </div>
                <p className="mb-2">
                  We also permit third parties and service providers to use
                  online tracking technologies on our Services for analytics and
                  advertising, including to help manage and display
                  advertisements, to tailor advertisements to your interests, or
                  to send abandoned shopping cart reminders (depending on your
                  communication preferences). The third parties and service
                  providers use their technology to provide advertising about
                  products and services tailored to your interests which may
                  appear either on our Services or on other websites.
                </p>
                <p className="mb-2">
                  To the extent these online tracking technologies are deemed to
                  be a “sale”/”sharing” (which includes targeted advertising, as
                  defined under the applicable laws) under applicable US state
                  laws, you can opt out of these online tracking technologies by
                  submitting a request as described below under section “DO
                  UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?“
                </p>
                <p className="my-2">Google Analytics</p>
                <p className="mb-2">
                  We may share your information with Google Analytics to track
                  and analyze the use of the Services. To opt out of being
                  tracked by Google Analytics across the Services, visit
                  https://tools.google.com/dlpage/gaoptout. For more information
                  on the privacy practices of Google, please visit the Google
                  Privacy & Terms page.
                </p>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  6. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?
                </h2>
                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <em className="text-gray-300">
                    In Short: We offer products, features, or tools powered by
                    artificial intelligence, machine learning, or similar
                    technologies.
                  </em>
                </div>
                <p className="my-2">
                  As part of our Services, we offer products, features, or tools
                  powered by artificial intelligence, machine learning, or
                  similar technologies (collectively, “AI Products”). These
                  tools are designed to enhance your experience and provide you
                  with innovative solutions. The terms in this Privacy Notice
                  govern your use of the AI Products within our Services.
                </p>
                <p className="my-2">Use of AI Technologies</p>
                <p className="my-2">
                  We provide the AI Products through third-party service
                  providers (“AI Service Providers”), including OpenAI. As
                  outlined in this Privacy Notice, your input, output, and
                  personal information will be shared with and processed by
                  these AI Service Providers to enable your use of our AI
                  Products for purposes outlined in “WHAT LEGAL BASES DO WE RELY
                  ON TO PROCESS YOUR PERSONAL INFORMATION?” You must not use the
                  AI Products in any way that violates the terms or policies of
                  any AI Service Provider.
                </p>
                <p className="my-2">Our AI Products</p>
                <p className="my-2">Our AI Products are designed for the following functions:</p>

                <ul className="list-disc  pl-6 space-y-4">
                  <li>AI document generation</li>
                </ul>
                <p className="my-3">How We Process Your Data Using AI</p>
                <p className="my-2">
                  All personal information processed using our AI Products is
                  handled in line with our Privacy Notice and our agreement with
                  third parties. This ensures high security and safeguards your
                  personal information throughout the process, giving you peace
                  of mind about your data’s safety.
                </p>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </h2>
                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <em className="text-gray-300 my-2">
                    In Short: If you choose to register or log in to our
                    Services using a social media account, we may have access to
                    certain information about you.
                  </em>
                </div>
                <p className="my-2">
                  Our Services offer you the ability to register and log in
                  using your third-party social media account details (like your
                  Facebook or X logins). Where you choose to do this, we will
                  receive certain profile information about you from your social
                  media provider. The profile information we receive may vary
                  depending on the social media provider concerned, but will
                  often include your name, email address, friends list, and
                  profile picture, as well as other information you choose to
                  make public on such a social media platform.
                </p>
                <p className="my-2">
                  We will use the information we receive only for the purposes
                  that are described in this Privacy Notice or that are
                  otherwise made clear to you on the relevant Services. Please
                  note that we do not control, and are not responsible for,
                  other uses of your personal information by your third-party
                  social media provider. We recommend that you review their
                  privacy notice to understand how they collect, use, and share
                  your personal information, and how you can set your privacy
                  preferences on their sites and apps.
                </p>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  8. HOW LONG DO WE KEEP YOUR INFORMATION?
                </h2>
                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <em className="text-gray-300 my-2">
                    In Short: We keep your information for as long as necessary
                    to fulfill the purposes outlined in this Privacy Notice
                    unless otherwise required by law.
                  </em>
                </div>
                <p className="my-2">
                  We will only keep your personal information for as long as it
                  is necessary for the purposes set out in this Privacy Notice,
                  unless a longer retention period is required or permitted by
                  law (such as tax, accounting, or other legal requirements). No
                  purpose in this notice will require us keeping your personal
                  information for longer than the period of time in which users
                  have an account with us.
                </p>
                <p className="my-2">
                  When we have no ongoing legitimate business need to process
                  your personal information, we will either delete or anonymize
                  such information, or, if this is not possible (for example,
                  because your personal information has been stored in backup
                  archives), then we will securely store your personal
                  information and isolate it from any further processing until
                  deletion is possible.
                </p>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  9. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </h2>
                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <em className="text-gray-300 my-2">
                    In Short: We aim to protect your personal information
                    through a system of organizational and technical security
                    measures.
                  </em>
                </div>
                <p className="my-2">
                  We have implemented appropriate and reasonable technical and
                  organizational security measures designed to protect the
                  security of any personal information we process. However,
                  despite our safeguards and efforts to secure your information,
                  no electronic transmission over the Internet or information
                  storage technology can be guaranteed to be 100% secure, so we
                  cannot promise or guarantee that hackers, cybercriminals, or
                  other unauthorized third parties will not be able to defeat
                  our security and improperly collect, access, steal, or modify
                  your information. Although we will do our best to protect your
                  personal information, transmission of personal information to
                  and from our Services is at your own risk. You should only
                  access the Services within a secure environment.
                </p>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  10. DO WE COLLECT INFORMATION FROM MINORS?
                </h2>
                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <em className="text-gray-300 my-2">
                    In Short: We do not knowingly collect data from or market to
                    children under 18 years of age.
                  </em>
                </div>
                <p className="my-2">
                  We do not knowingly collect, solicit data from, or market to
                  children under 18 years of age, nor do we knowingly sell such
                  personal information. By using the Services, you represent
                  that you are at least 18 or that you are the parent or
                  guardian of such a minor and consent to such minor dependent’s
                  use of the Services. If we learn that personal information
                  from users less than 18 years of age has been collected, we
                  will deactivate the account and take reasonable measures to
                  promptly delete such data from our records. If you become
                  aware of any data we may have collected from children under
                  age 18, please contact us at contact@synctankai.com.
                </p>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  11. WHAT ARE YOUR PRIVACY RIGHTS?
                </h2>
                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <em className="text-gray-300 my-2">
                    In Short: Depending on your state of residence in the US or
                    in some regions, such as the European Economic Area (EEA),
                    United Kingdom (UK), Switzerland, and Canada, you have
                    rights that allow you greater access to and control over
                    your personal information. You may review, change, or
                    terminate your account at any time, depending on your
                    country, province, or state of residence.
                  </em>
                </div>
                <p className="my-2">
                  In some regions (like the EEA, UK, Switzerland, and Canada),
                  you have certain rights under applicable data protection laws.
                  These may include the right (i) to request access and obtain a
                  copy of your personal information, (ii) to request
                  rectification or erasure; (iii) to restrict the processing of
                  your personal information; (iv) if applicable, to data
                  portability; and (v) not to be subject to automated
                  decision-making. In certain circumstances, you may also have
                  the right to object to the processing of your personal
                  information. You can make such a request by contacting us by
                  using the contact details provided in the section “HOW CAN YOU
                  CONTACT US ABOUT THIS NOTICE?” below.
                </p>
                <p className="my-2">
                  We will consider and act upon any request in accordance with
                  applicable data protection laws.
                </p>
                <p className="my-2">
                  If you are located in the EEA or UK and you believe we are
                  unlawfully processing your personal information, you also have
                  the right to complain to your Member State data protection
                  authority or UK data protection authority.
                </p>
                <p className="my-2">
                  If you are located in Switzerland, you may contact the Federal
                  Data Protection and Information Commissioner.
                </p>
                <p className="my-2">
                  Withdrawing your consent: If we are relying on your consent to
                  process your personal information, which may be express and/or
                  implied consent depending on the applicable law, you have the
                  right to withdraw your consent at any time. You can withdraw
                  your consent at any time by contacting us by using the contact
                  details provided in the section “HOW CAN YOU CONTACT US ABOUT
                  THIS NOTICE?” below.
                </p>
                <p className="my-2">
                  However, please note that this will not affect the lawfulness
                  of the processing before its withdrawal nor, when applicable
                  law allows, will it affect the processing of your personal
                  information conducted in reliance on lawful processing grounds
                  other than consent.
                </p>
                <p className="my-2">
                  Opting out of marketing and promotional communications: You
                  can unsubscribe from our marketing and promotional
                  communications at any time by clicking on the unsubscribe link
                  in the emails that we send, replying “STOP” or “UNSUBSCRIBE”
                  to the SMS messages that we send, or by contacting us using
                  the details provided in the section “HOW CAN YOU CONTACT US
                  ABOUT THIS NOTICE?” below. You will then be removed from the
                  marketing lists. However, we may still communicate with you —
                  for example, to send you service-related messages that are
                  necessary for the administration and use of your account, to
                  respond to service requests, or for other non-marketing
                  purposes.
                </p>
                <p className="my-2">Account Information</p>
                <p className="my-2">
                  If you would at any time like to review or change the
                  information in your account or terminate your account, you
                  can:
                </p>
                <ul className="list-disc  pl-6 space-y-4">
                  <li>
                    Log in to your account settings and update your user
                    account.
                  </li>
                </ul>
                <p className="my-2">
                  Upon your request to terminate your account, we will
                  deactivate or delete your account and information from our
                  active databases. However, we may retain some information in
                  our files to prevent fraud, troubleshoot problems, assist with
                  any investigations, enforce our legal terms and/or comply with
                  applicable legal requirements.
                </p>
                <p className="my-2">
                  Cookies and similar technologies: Most Web browsers are set to
                  accept cookies by default. If you prefer, you can usually
                  choose to set your browser to remove cookies and to reject
                  cookies. If you choose to remove cookies or reject cookies,
                  this could affect certain features or services of our
                  Services. For further information, please see our Cookie
                  Notice.
                </p>
                <p className="my-2">
                  If you have questions or comments about your privacy rights,
                  you may email us at contact@synctankai.com.
                </p>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  12. CONTROLS FOR DO-NOT-TRACK FEATURES
                </h2>
                <p className="my-2">
                  Most web browsers and some mobile operating systems and mobile
                  applications include a Do-Not-Track (“DNT”) feature or setting
                  you can activate to signal your privacy preference not to have
                  data about your online browsing activities monitored and
                  collected. At this stage, no uniform technology standard for
                  recognizing and implementing DNT signals has been finalized.
                  As such, we do not currently respond to DNT browser signals or
                  any other mechanism that automatically communicates your
                  choice not to be tracked online. If a standard for online
                  tracking is adopted that we must follow in the future, we will
                  inform you about that practice in a revised version of this
                  Privacy Notice. California law requires us to let you know how
                  we respond to web browser DNT signals. Because there currently
                  is not an industry or legal standard for recognizing or
                  honoring DNT signals, we do not respond to them at this time.
                </p>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  13. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </h2>
                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <em className="text-gray-300 my-2">
                    In Short: If you are a resident of California, Colorado,
                    Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky,
                    Montana, New Hampshire, New Jersey, Oregon, Tennessee,
                    Texas, Utah, or Virginia, you may have the right to request
                    access to and receive details about the personal information
                    we maintain about you and how we have processed it, correct
                    inaccuracies, get a copy of, or delete your personal
                    information. You may also have the right to withdraw your
                    consent to our processing of your personal information.
                    These rights may be limited in some circumstances by
                    applicable law. More information is provided below.
                  </em>
                </div>
                <p className="my-2">Categories of Personal Information We Collect</p>
                <p className="my-2">
                  We have collected the following categories of personal
                  information in the past twelve (12) months:
                </p>

                <table className="text-opacity-90 text-gray-300 font-light">
                  <tbody>
                    <tr>
                      <td>
                        <p className="my-2">Category</p>
                      </td>
                      <td>
                        <p className="my-2">Examples</p>
                      </td>
                      <td>
                        <p className="my-2">Collected</p>
                      </td>
                    </tr>
                    <tr className="bg-[#59a0fdc9]" >
                      <td>
                        <p className="my-2">A. Identifiers</p>
                        </td>
                        <td><p className="my-2">
                          Contact details, such as real name, alias, postal
                          address, telephone or mobile contact number, unique
                          personal identifier, online identifier, Internet
                          Protocol address, email address, and account name
                        </p>

                        </td>
                        <td>
                        <p className="my-2">YES</p>
                        </td>
                       </tr>
                      <tr>
                      <td>
                        <p className="my-2">
                          B. Personal information as defined in the California
                          Customer Records statute
                        </p>
                        </td>
                        <td> 
                        <p className="my-2">
                          Name, contact information, education, employment,
                          employment history, and financial information
                        </p>
                        </td>
                        <td>
                        <p className="my-2">YES</p>
                      </td>
                      </tr>
                      <tr >
                      <td>
                        <p className="my-2 ">
                          C. Protected classification characteristics under
                          state or federal law
                        </p>
                        </td>
                        <td>
                        <p className="my-2">
                          Gender, age, date of birth, race and ethnicity,
                          national origin, marital status, and other demographic
                          data
                        </p>
                        </td>
                        <td>
                        <p className="my-2">NO</p>
                      </td>
                    </tr>
                    <tr className="bg-[#59a0fdc9] ">
                      <td>
                        <p className="my-2">D. Commercial information</p>  </td> 
                        <td> <p className="my-2">
                          Transaction information, purchase history, financial
                          details, and payment information
                        </p></td>
                        <td>  <p className="my-2">NO</p></td> 
                      </tr>
                    <tr>
                      <td>
                        <p className="my-2">E. Biometric information</p></td>
                        <td> <p className="my-2">Fingerprints and voiceprints</p></td>
                        <td> <p className="my-2">NO</p></td>
                     </tr>
                     <tr className="bg-[#59a0fdc9]">
                      <td>
                        <p className="my-2">F. Internet or other similar network activity</p></td>
                        <td> <p className="my-2">
                          Browsing history, search history, online behavior,
                          interest data, and interactions with our and other
                          websites, applications, systems, and advertisements
                        </p></td>
                        <td> <p className="my-2">NO</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="my-2">G. Geolocation data</p></td>
                        <td> <p className="my-2">Device location</p></td>
                        <td> <p className="my-2">NO</p>
                      </td>
                    </tr>
                    <tr className="bg-[#59a0fdc9]">
                      <td>
                        <p className="my-2">
                          H. Audio, electronic, sensory, or similar information
                        </p></td>
                        <td> <p className="my-2">
                          Images and audio, video or call recordings created in
                          connection with our business activities
                        </p></td>
                        <td>  <p className="my-2">NO</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="my-2">I. Professional or employment-related information</p></td>
                        <td>  <p className="my-2">
                          Business contact details in order to provide you our
                          Services at a business level or job title, work
                          history, and professional qualifications if you apply
                          for a job with us
                        </p></td>
                        <td>  <p className="my-2">NO</p>
                      </td>
                    </tr>
                    <tr className="bg-[#59a0fdc9]">
                      <td>
                        <p className="my-2">J. Education Information</p></td>
                        <td>  <p className="my-2">Student records and directory information</p></td>
                        <td><p className="my-2">NO</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="my-2">
                          K. Inferences drawn from collected personal
                          information
                        </p></td>
                        <td>  <p className="my-2">
                          Inferences drawn from any of the collected personal
                          information listed above to create a profile or
                          summary about, for example, an individual’s
                          preferences and characteristics
                        </p></td>
                        <td>  <p className="my-2">NO</p>
                      </td>
                    </tr>
                    <tr className="bg-[#59a0fdc9]">
                      <td>
                        <p className="my-2">L. Sensitive personal Information</p></td>
                        <td>  <p className="my-2"></p></td>
                        <td>  <p className="my-2">NO</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="my-2">
                  We may also collect other personal information outside of
                  these categories through instances where you interact with us
                  in person, online, or by phone or mail in the context of:
                </p>
                <ul className="list-disc  pl-6 space-y-4">
                  <li>Receiving help through our customer support channels;</li>
                  <li>Participation in customer surveys or contests; and</li>
                  <li>
                    Facilitation in the delivery of our Services and to respond
                    to your inquiries.
                  </li>
                </ul>
                <p className="my-2">Sources of Personal Information</p>
                <p className="my-2">
                  Learn more about the sources of personal information we
                  collect in “WHAT INFORMATION DO WE COLLECT?“
                </p>
                <p className="my-2">How We Use and Share Personal Information</p>
                <p className="my-2">
                  Learn about how we use your personal information in the
                  section, “HOW DO WE PROCESS YOUR INFORMATION?“ Will your
                  information be shared with anyone else?
                </p>
                <p className="my-2">
                  We may disclose your personal information with our service
                  providers pursuant to a written contract between us and each
                  service provider. Learn more about how we disclose personal
                  information to in the section, “WHEN AND WITH WHOM DO WE SHARE
                  YOUR PERSONAL INFORMATION?“
                </p>
                <p className="my-2">
                  We may use your personal information for our own business
                  purposes, such as for undertaking internal research for
                  technological development and demonstration. This is not
                  considered to be “selling” of your personal information.
                </p>
                <p className="my-2">
                  We have not disclosed, sold, or shared any personal
                  information to third parties for a business or commercial
                  purpose in the preceding twelve (12) months. We will not sell
                  or share personal information in the future belonging to
                  website visitors, users, and other consumers.
                </p>
                <p className="my-2">Your Rights</p>
                <p className="my-2">
                  You have rights under certain US state data protection laws.
                  However, these rights are not absolute, and in certain cases,
                  we may decline your request as permitted by law. These rights
                  include:
                </p>
                <ul className="list-disc  pl-6 space-y-4">
                  <li>
                    Right to know whether or not we are processing your personal
                    data
                  </li>
                  <li>Right to access your personal data</li>
                  <li>Right to correct inaccuracies in your personal data</li>
                  <li>Right to request the deletion of your personal data</li>
                  <li>
                    Right to obtain a copy of the personal data you previously
                    shared with us
                  </li>
                  <li>
                    Right to non-discrimination for exercising your rights
                  </li>
                  <li>
                    Right to opt out of the processing of your personal data if
                    it is used for targeted advertising (or sharing as defined
                    under California’s privacy law), the sale of personal data,
                    or profiling in furtherance of decisions that produce legal
                    or similarly significant effects (“profiling”)
                  </li>
                </ul>

                <p className="my-2">
                  Depending upon the state where you live, you may also have the
                  following rights:
                </p>
                <li>
                  Right to obtain a list of the categories of third parties to
                  which we have disclosed personal data (as permitted by
                  applicable law, including California’s and Delaware’s privacy
                  law)
                </li>
                <li>
                  Right to obtain a list of specific third parties to which we
                  have disclosed personal data (as permitted by applicable law,
                  including Oregon’s privacy law)
                </li>
                <li>
                  Right to limit use and disclosure of sensitive personal data
                  (as permitted by applicable law, including California’s
                  privacy law)
                </li>
                <li>
                  Right to opt out of the collection of sensitive data and
                  personal data collected through the operation of a voice or
                  facial recognition feature (as permitted by applicable law,
                  including Florida’s privacy law)
                </li>
                <p className="my-2">How to Exercise Your Rights</p>
                <p className="my-2">
                  To exercise these rights, you can contact us by referring to
                  the contact details at the bottom of this document.
                </p>
                <p className="my-2">
                  We will honor your opt-out preferences if you enact the Global
                  Privacy Control (GPC) opt-out signal on your browser.
                </p>
                <p className="my-2">
                  Under certain US state data protection laws, you can designate
                  an authorized agent to make a request on your behalf. We may
                  deny a request from an authorized agent that does not submit
                  proof that they have been validly authorized to act on your
                  behalf in accordance with applicable laws.
                </p>
                <p className="my-2">Request Verification</p>
                <p className="my-2">
                  Upon receiving your request, we will need to verify your
                  identity to determine you are the same person about whom we
                  have the information in our system. We will only use personal
                  information provided in your request to verify your identity
                  or authority to make the request. However, if we cannot verify
                  your identity from the information already maintained by us,
                  we may request that you provide additional information for the
                  purposes of verifying your identity and for security or
                  fraud-prevention purposes.
                </p>
                <p className="my-2">
                  If you submit the request through an authorized agent, we may
                  need to collect additional information to verify your identity
                  before processing your request and the agent will need to
                  provide a written and signed permission from you to submit
                  such request on your behalf.
                </p>
                <p className="my-2">Appeals</p>
                <p className="my-2">
                  Under certain US state data protection laws, if we decline to
                  take action regarding your request, you may appeal our
                  decision by emailing us at contact@synctankai.com. We will
                  inform you in writing of any action taken or not taken in
                  response to the appeal, including a written explanation of the
                  reasons for the decisions. If your appeal is denied, you may
                  submit a complaint to your state attorney general.
                </p>
                <p className="my-2">California “Shine The Light” Law</p>
                <p className="my-2">
                  California Civil Code Section 1798.83, also known as the
                  “Shine The Light” law, permits our users who are California
                  residents to request and obtain from us, once a year and free
                  of charge, information about categories of personal
                  information (if any) we disclosed to third parties for direct
                  marketing purposes and the names and addresses of all third
                  parties with which we shared personal information in the
                  immediately preceding calendar year. If you are a California
                  resident and would like to make such a request, please submit
                  your request in writing to us by using the contact details
                  provided in the section “HOW CAN YOU CONTACT US ABOUT THIS
                  NOTICE?“
                </p>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  14. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
                </h2>
                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <em className="text-gray-300">
                    In Short: You may have additional rights based on the
                    country you reside in.
                  </em>
                </div>
                <p className="my-2">Australia and New Zealand</p>
                <p className="my-2">
                  We collect and process your personal information under the
                  obligations and conditions set by Australia’s Privacy Act 1988
                  and New Zealand’s Privacy Act 2020 (Privacy Act).
                </p>
                <p className="my-2">
                  This Privacy Notice satisfies the notice requirements defined
                  in both Privacy Acts, in particular: what personal information
                  we collect from you, from which sources, for which purposes,
                  and other recipients of your personal information.
                </p>
                <p className="my-2">
                  If you do not wish to provide the personal information
                  necessary to fulfill their applicable purpose, it may affect
                  our ability to provide our services, in particular:
                </p>
                <ul className="list-disc  pl-6 space-y-4">
                  <li>offer you the products or services that you want</li>
                  <li>respond to or help with your requests</li>
                  <li>manage your account with us</li>
                  <li>confirm your identity and protect your account</li>
                </ul>
                <p className="my-2">
                  At any time, you have the right to request access to or
                  correction of your personal information. You can make such a
                  request by contacting us by using the contact details provided
                  in the section “HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA
                  WE COLLECT FROM YOU?“
                </p>
                <p className="my-2">
                  If you believe we are unlawfully processing your personal
                  information, you have the right to submit a complaint about a
                  breach of the Australian Privacy Principles to the Office of
                  the Australian Information Commissioner and a breach of New
                  Zealand’s Privacy Principles to the Office of New Zealand
                  Privacy Commissioner.
                </p>
                <p className="my-2">Republic of South Africa</p>
                <p className="my-2">
                  At any time, you have the right to request access to or
                  correction of your personal information. You can make such a
                  request by contacting us by using the contact details provided
                  in the section “HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA
                  WE COLLECT FROM YOU?“
                </p>
                <p className="my-2">
                  If you are unsatisfied with the manner in which we address any
                  complaint with regard to our processing of personal
                  information, you can contact the office of the regulator, the
                  details of which are:
                </p>
                <p className="my-2">The Information Regulator (South Africa)</p>
                <p className="my-2">General enquiries: enquiries@inforegulator.org.za</p>
                <p className="my-2">
                  Complaints (complete POPIA/PAIA form 5):
                  PAIAComplaints@inforegulator.org.za &
                  POPIAComplaints@inforegulator.org.za
                </p>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  15. DO WE MAKE UPDATES TO THIS NOTICE?
                </h2>
                <div className="bg-opacity-20 bg-gray-800 rounded-lg">
                  <em className="text-gray-300">
                    In Short: Yes, we will update this notice as necessary to
                    stay compliant with relevant laws.
                  </em>
                </div>
                <p className="my-2">
                  We may update this Privacy Notice from time to time. The
                  updated version will be indicated by an updated “Revised” date
                  at the top of this Privacy Notice. If we make material changes
                  to this Privacy Notice, we may notify you either by
                  prominently posting a notice of such changes or by directly
                  sending you a notification. We encourage you to review this
                  Privacy Notice frequently to be informed of how we are
                  protecting your information.
                </p>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </h2>
                <p className="my-2">
                  If you have questions or comments about this notice, you may
                  contact us by email at contact@synctankai.com.
                </p>
              </section>

              <section>
                <h2 className="text-4xl font-semibold mb-6">
                  17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </h2>
                <p className="my-2">
                  Based on the applicable laws of your country or state of
                  residence in the US, you may have the right to request access
                  to the personal information we collect from you, details about
                  how we have processed it, correct inaccuracies, or delete your
                  personal information. You may also have the right to withdraw
                  your consent to our processing of your personal information.
                  These rights may be limited in some circumstances by
                  applicable law. To request to review, update, or delete your
                  personal information, please contact us at
                  contact@synctankai.com.
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>
      <CookieConsent />
      <Footer />
    </div>
  );
}
