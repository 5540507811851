import React from 'react'
import ArrowVector from '../assets/ArrowVector.png'

function HowWeAreDifferent() {
  return (
    <div>
      <section  className='flex flex-col items-center px-4  w-full gap-6 md:gap-10 bg-white pb-20'>
       <section className='flex flex-col md:flex-row px-2 md:px-[20%] bg-white justify-between text-left items-center '>
      
      <div className="space-y-6 my-14">
      <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-5xl font-semibold  mb-12 text-[#2559EC]">
        How We're Different
       </h2>
       <div className='flex flex-row space-x-4'>
       <img src={ArrowVector} alt="" className='h-1/2 mt-2' />
<p className="">
Fluffy is your go-to solution for maintaining a robust and active social media presence. 
           </p>
       </div>
       <div className='flex flex-row space-x-4'>
       <img src={ArrowVector} alt="" className='h-1/2 mt-2' />
           <p className="">
           This AI-driven tool is designed specifically for small businesses and content creators, ensuring that your social media accounts remain engaging and up-to-date, even when you're focused on your core operations.
           </p>
           </div>
           <div className='flex flex-row space-x-4'>
           <img src={ArrowVector} alt="" className='h-1/2 mt-2' />
           <p className="">
           Unlike most other content creation tools out there, like AdCreative and Predis, Fluffy goes beyond standard automation, offering <span className="font-semibold">humanized output with social awareness</span> to create authentic and meaningful interactions with your audience. 
           </p>
           </div>
           <div className='flex flex-row space-x-4'>
           <img src={ArrowVector} alt="" className='h-1/2 mt-2' />
           <p className="">
           By adapting to real-time trends and being sensitive to social issues, Fluffy ensures that your brand remains relevant and connected to your audience's values.
           </p>
           </div>
           <div className='flex flex-row space-x-4'>
           <img src={ArrowVector} alt="" className='h-1/2 mt-2' />
           <p className="">
           Experience the future of social media management with Fluffy. As part of our beta testing program, you'll get early access to Fluffy, providing invaluable feedback to shape its development. Answer five quick questions to apply and be among the first to try Fluffy.
           </p>
           </div>
         </div>
     </section>
        <img src="frame3.png" alt="" className=' w-full sm:max-w-2xl md:max-w-4xl lg:max-w-6xl' />
      </section>
    </div>
  )
}

export default HowWeAreDifferent
