import React from 'react'

function Questions() {
  return (
    <div id='FAQ'>
      
      <section className="bg-blue-600 text-white py-16 md:py-28 px-4" style={{
      backgroundImage: `url('/contactimg.svg')`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center',
      }}>
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl md:text-[64px] leading-[65px]  font-bold mb-4">Have More Questions?</h2>
          <p className="text-lg md:text-xl mb-8">Want to Join Us or to Collaborate? Feel free to send us an email.</p>
          <button className="bg-black text-white px-6 md:px-8 py-3 rounded-2xl text-base md:text-lg font-semibold">Contact us</button>
        </div>
      </section>
    </div>
  )
}

export default Questions
