import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const handleLinkClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const headerOffset = 80; // Adjust this value based on your header height
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    setMenuOpen(false);
  };
  return (
    <div>
      <header className="container mx-auto text-white px-4 py-6 flex justify-between items-center">
        <img src="flufflogo.png" alt="" className="w-[114px] h-[54px]" />
        <nav
          className={`${
            menuOpen ? "flex" : "hidden"
          } md:flex flex-col md:flex-row absolute md:relative md:top-0 top-16 left-0 right-0 bg-blue-600 md:bg-transparent z-50 md:space-x-6 space-y-4 md:space-y-0 p-4 md:p-0`}
        >
          {location.pathname !== "/policy" ? (
            <>
              <Link
                to="/marketers"
                className="hover:text-gray-200 hover:underline"
              >
                For Marketers
              </Link>
              <a
                href="https://chromewebstore.google.com/detail/fluffy-comment-drafter/ihicjdifnmmccimpnaggfanhhbgjnako"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-200 hover:underline"
              >
                Comment Drafter
              </a>
              <a
                href="#About"
                className="hover:text-gray-200 hover:underline"
                onClick={(e) => handleLinkClick(e, "About")}
              >
                About
              </a>
              <a
                href="#Pricing"
                className="hover:text-gray-200 hover:underline"
                onClick={(e) => handleLinkClick(e, "Pricing")}
              >
                Pricing
              </a>
              <a
                href="#Features"
                className="hover:text-gray-200 hover:underline"
                onClick={(e) => handleLinkClick(e, "Features")}
              >
                Features
              </a>
              <a
                href="#FAQ"
                className="hover:text-gray-200 hover:underline"
                onClick={(e) => handleLinkClick(e, "FAQ")}
              >
                FAQ
              </a>
            </>
          ) : (
            <>
              <Link
                to="/individuals"
                className="hover:text-gray-200 hover:underline"
              >
                For Individuals
              </Link>
              <Link
                to="/marketers"
                className="hover:text-gray-200 hover:underline"
              >
                For Marketers
              </Link>
            </>
          )}
        </nav>
        <div className="flex items-center space-x-4">
          {/* <button className="hover:text-gray-200 hidden md:block">Login</button> */}

          <a
            href="https://app.fluffy-ai.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className=" text-blue-600 bg-white px-4 py-2 rounded-full  hidden md:block border-white border hover:-translate-y-1 animate-pulse">
              Go to App
            </button>
          </a>

          <button className="md:hidden" onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? "Close" : "Menu"}
          </button>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
