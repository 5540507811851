export default function Pricing() {
    const plans = [
      {
        name: "Base",
        price: "5.00",
        features: [
          "$5 wallet credit",
          "No expiration",
          "Early access to new features"
        ]
      },
      {
        name: "Advanced",
        price: "10.00",
        features: [
          "$10 wallet credit",
          "No expiration",
          "Early access to new features"
        ]
      },
      {
        name: "Pro",
        price: "50.00",
        features: [
          "$50 wallet credit",
          "No expiration",
          "Early access to new features"
        ]
      }
    ];
  
    return (
      <div id= "Pricing" className="bg-blue-600 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-bold text-white text-center mb-4">Pricing</h2>
          <p className="text-white text-center mb-12">
            Fluffy™ offers a suite of tools designed to streamline your marketing processes
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {plans.map((plan) => (
              <div key={plan.name} className="bg-white rounded-lg shadow-lg p-8">
                <h3 className="text-xl font-semibold text-left mb-2">{plan.name}</h3>
                <div className="flex items-center mb-4 ">
                  <span className="text-5xl font-cormorant font-bold ">${plan.price}</span>
                  <span className="ml-2 text-sm bg-gradient-to-r from-[#FFB2A1] to-[#9747FF] text-white px-2 py-1 rounded-2xl">One time</span>
                </div>
                <p className="text-gray-600 text-left mb-6">Adds ${plan.price === "5.00" ? "5" : plan.price.slice(0, 2)} to your account</p>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-center text-left">
                      <svg className="w-6 h-6 text-[2B1858] bg-[#997AE1] rounded-full p-1 mr-2 border-2 border-[#D0C2F1] " fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
                <a href="https://app.fluffy-ai.com/" target="_blank" rel="noopener noreferrer">
                <button  className="w-full bg-blue-600 text-white py-2 px-4 rounded-3xl hover:bg-blue-700 transition-colors ">
                  Buy Credits
                </button>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  
  