import React from "react";
import Slider from "react-slick";
import MediaCaptioning from "../assets/Media Captioning.png";
import MediaCaptioning2 from "../assets/Media Captioning 2.png";
import TrendTracking from "../assets/trend tracking.png";
import TrendTracking2 from "../assets/trend tracking 2.png";
import ConceptSuggestions from "../assets/Concept Suggestions.png";
import ConceptSuggestions2 from "../assets/concept suggestion.png";
import PostCopy from "../assets/Post Copy.png";
import PostCopy2 from "../assets/Post Copy 2.png";
import MassProd from "../assets/Mass production.png";
import MassProd2 from "../assets/Mass production 2.png";
import Emotional from "../assets/Emotional Sensitivity.png";
import Emotional2 from "../assets/Emotional Sensitivity 2.png";
import FVedio from "../assets/video_caps.mp4";
import TTVedio from "../assets/live trend tracking.mp4";

import ex0 from "../assets/ex0.png";
import ex1 from "../assets/ex1.png";
import ex2 from "../assets/ex2.png";
import ex3 from "../assets/ex3.png";
import ex4 from "../assets/ex4.png";
import Hero from "../assets/hero.png";

const features = [
  {
    title: "Image to post",
    description:
      "Emotionally sensitive analysis of your images that drafts social content based on the story behind them.",
    image: [PostCopy],
    images: [Hero, ex0, ex1, ex2, ex3, ex4],
  },
  {
    title: "Concept Suggestions",
    description:
      "Suggest writing ideas for your target audience based on current trends.",
    image: [ConceptSuggestions, ConceptSuggestions2],
  },
  {
    title: "Video Captioning (upcoming)",
    description:
      "Automatically generates dynamic, context-aware captions for videos.",
    image: [MediaCaptioning, MediaCaptioning2],
    vedio: FVedio,
  },
  {
    title: "Trend Tracking (upcoming)",
    description:
      "Fluffy automatically monitors live trends across your active platforms to make sure your content stays relevant.",
    image: [TrendTracking, TrendTracking2],
    vedio: TTVedio,
  },
  // {
  //   title: "Mass Production",
  //   description:
  //     "Fluffy automatically monitors live trends across your active platforms to make sure your content stays relevant.",
  //   image: [MassProd, MassProd2],
  // },
  // {
  //   title: "Emotional Sensitivity",
  //   description:
  //     "Fluffy automatically monitors live trends across your active platforms to make sure your content stays relevant.",
  //   image: [Emotional, Emotional2],
  // },
];

const Features = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };
  return (
    <div
      id="Features"
      className="min-h-screen bg-white flex items-center justify-center"
    >
      <div className="lg:max-w-[calc(100%-20rem)] mx-auto md:p-4 mt-16  lg:p-12">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h2 className="text-5xl font-semibold mb-4">Features</h2>
          <p className="max-w-2xl text-xl mx-auto">
            Fluffy offers a suite of content creation tools that use AI to
            streamline your writing process.
          </p>
          <a
            href="https://app.fluffy-ai.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="mt-6 bg-blue-600 text-white px-8 py-3 rounded-full font-medium hover:bg-blue-700 transition-colors">
              Start Using Our App
            </button>
          </a>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-row gap-12 ">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`flex flex-col xl:flex-row justify-between gap-10 bg-[#F5F5FF] px-6 md:px-16 py-8  rounded-3xl items-center ${
                index % 2 === 0 ? "" : "xl:flex-row-reverse"
              }`}
            >
              <div className="flex-1 max-w-md">
                <div className="flex flex-row gap-2 items-center mb-4">
                  <img
                    src={feature.image[0]}
                    alt={feature.title}
                    className="w-8 h-8 sm:h-16 sm:w-16 rounded-lg"
                  />

                  <h3 className="text-xl md:text-3xl font-semibold pb-2 bg-gradient-to-r from-blue-600  to-[#98a9fc] to-100% bg-clip-text text-transparent">
                    {feature.title}
                  </h3>
                </div>

                <p className="text-left text-base md:font-semibold">
                  {feature.description}
                </p>
              </div>
              {/* <div className="">
                {index === 0 || index === 1 ? (
                  <video
                    src={feature.vedio}
                    className="w-full h-auto max-h-[550px] max-w-xl rounded-lg"
                    loop
                    autoPlay
                    muted
                    playsInline
                  ></video>
                ) : (
                  <img
                    src={feature.image[1]}
                    alt={feature.title}
                    className="w-96 min-w-56"
                  />
                )}
              </div> */}

              <div key={feature.id} className="">
                {index === 2 || index === 3 ? (
                  <video
                    src={feature.vedio}
                    className="w-full h-auto max-h-[550px] max-w-xl rounded-lg"
                    loop
                    autoPlay
                    muted
                    playsInline
                  ></video>
                ) : index === 0 ? (
                  <Slider {...settings} className="w-96 min-w-56">
                    {feature.images.map((img, i) => (
                      <img key={i} src={img} alt={`${feature.title} ${i}`} />
                    ))}
                  </Slider>
                ) : (
                  <img
                    src={feature.image[1]}
                    alt={feature.title}
                    className="w-96 min-w-56"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
