// import { useState, useEffect, useRef } from 'react'
// import { X, Cookie, ChevronDown, ChevronUp } from 'lucide-react'

// export default function CookieConsent() {
//   const [isOpen, setIsOpen] = useState(false)
//   const [showSmallDiv, setShowSmallDiv] = useState(false)
//   const [expandedSections, setExpandedSections] = useState({
//     necessary: false,
//     functional: false,
//     analytics: false,
//     performance: false
//   })
//   const [preferences, setPreferences] = useState({
//     necessary: true, // Always true
//     functional: false,
//     analytics: false,
//     performance: false
//   })
//   const [showFloatingButton, setShowFloatingButton] = useState(false)
//   const modalRef = useRef(null)

//   useEffect(() => {
//     // Check if user has already made a choice this session
//     const sessionPreferences = sessionStorage.getItem('cookiePreferences')
//     if (!sessionPreferences) {
//       setShowSmallDiv(true)
//     } else {
//       setShowFloatingButton(true)
//       setPreferences(JSON.parse(sessionPreferences))
//     }

//     // Click outside handler
//     const handleClickOutside = (event) => {
//       if (modalRef.current && !modalRef.current.contains(event.target)) {
//         setIsOpen(false)
//       }
//     }

//     document.addEventListener('mousedown', handleClickOutside)
//     return () => document.removeEventListener('mousedown', handleClickOutside)
//   }, [])

//   const toggleSection = (section) => {
//     setExpandedSections(prev => ({
//       ...prev,
//       [section]: !prev[section]
//     }))
//   }

//   const handleSave = (type) => {
//     let newPreferences = { ...preferences }
    
//     if (type === 'accept-all') {
//       newPreferences = {
//         necessary: true,
//         functional: true,
//         analytics: true,
//         performance: true
//       }
//     } else if (type === 'reject-all') {
//       newPreferences = {
//         necessary: true,
//         functional: false,
//         analytics: false,
//         performance: false
//       }
//     }

//     sessionStorage.setItem('cookiePreferences', JSON.stringify(newPreferences))
//     setPreferences(newPreferences)
//     setIsOpen(false)
//     setShowSmallDiv(false)
//     setShowFloatingButton(true)
//   }

//   return (
//     <>
//       {/* Small Cookie Div */}
//       {showSmallDiv && (
//         <div className="fixed bottom-4 right-4 bg-gray-900 text-white p-4 rounded-lg shadow-lg max-w-sm z-50">
//           <p className="text-sm mb-2">
//             We use cookies to enhance your browsing experience and analyze our traffic.
//           </p>
//           <p className="text-sm mb-4">
//             By clicking "Accept All", you consent to our use of cookies.
//           </p>
//           <div className="flex flex-col sm:flex-row gap-2">
//             <button
//               onClick={() => handleSave('reject-all')}
//               className="px-3 py-1 text-sm border border-gray-600 rounded-md hover:bg-gray-800 transition-colors"
//             >
//               Reject All
//             </button>
//             <button
//               onClick={() => {
//                 setIsOpen(true)
//                 setShowSmallDiv(false)
//               }}
//               className="px-3 py-1 text-sm border border-gray-600 rounded-md hover:bg-gray-800 transition-colors"
//             >
//               Preferences
//             </button>
//             <button
//               onClick={() => handleSave('accept-all')}
//               className="px-3 py-1 text-sm bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
//             >
//               Accept All
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Floating Cookie Button */}
//       {showFloatingButton && !isOpen && (
//         <button
//           onClick={() => setIsOpen(true)}
//           className="fixed bottom-4 left-4 p-3 bg-gray-900 text-white rounded-full shadow-lg hover:bg-gray-800 transition-colors z-50"
//           aria-label="Cookie Settings"
//         >
//           <Cookie className="w-6 h-6" />
//         </button>
//       )}

//       {/* Cookie Consent Modal */}
//       {isOpen && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
//           <div 
//             ref={modalRef}
//             className="bg-gray-900 text-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto"
//           >
//             <div className="p-6 space-y-6">
//               {/* Header */}
//               <div className="flex justify-between items-center">
//                 <h2 className="text-xl font-semibold">Customize Consent Preferences</h2>
//                 <button
//                   onClick={() => setIsOpen(false)}
//                   className="text-gray-400 hover:text-white transition-colors"
//                   aria-label="Close"
//                 >
//                   <X className="w-6 h-6" />
//                 </button>
//               </div>

//               {/* Description */}
//               <p className="text-gray-300">
//                 We use cookies to help you navigate efficiently and perform certain functions. 
//                 You will find detailed information about all cookies under each consent category below.
//               </p>

//               {/* Cookie Sections */}
//               <div className="space-y-4 text-left">
//                 {/* Necessary */}
//                 <div className="border border-gray-700 rounded-lg">
//                   <button
//                     onClick={() => toggleSection('necessary')}
//                     className="w-full px-4 py-3 flex justify-between items-center"
//                   >
//                     <div className="flex items-center gap-2">
//                       <span className="font-medium">Necessary</span>
//                       <span className="text-sm text-green-400">Always Active</span>
//                     </div>
//                     {expandedSections.necessary ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
//                   </button>
//                   {expandedSections.necessary && (
//                     <div className="px-4 py-3 border-t border-gray-700">
//                       <p className="text-gray-300 text-sm">
//                         Necessary cookies are required to enable the basic features of this site, such as providing secure log-in 
//                         or adjusting your consent preferences. These cookies do not store any personally identifiable data.
//                       </p>
//                     </div>
//                   )}
//                 </div>

//                 {/* Functional */}
//                 <div className="border border-gray-700 rounded-lg">
//                   <button
//                     onClick={() => toggleSection('functional')}
//                     className="w-full px-4 py-3 flex justify-between items-center"
//                   >
//                     <span className="font-medium">Functional</span>
//                     {expandedSections.functional ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
//                   </button>
//                   {expandedSections.functional && (
//                     <div className="px-4 py-3 border-t border-gray-700">
//                       <p className="text-gray-300 text-sm">
//                         Functional cookies help perform certain functionalities like sharing the content of the website on social media 
//                         platforms, collecting feedback, and other third-party features.
//                       </p>
//                     </div>
//                   )}
//                 </div>

//                 {/* Analytics */}
//                 <div className="border border-gray-700 rounded-lg">
//                   <button
//                     onClick={() => toggleSection('analytics')}
//                     className="w-full px-4 py-3 flex justify-between items-center"
//                   >
//                     <span className="font-medium">Analytics</span>
//                     {expandedSections.analytics ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
//                   </button>
//                   {expandedSections.analytics && (
//                     <div className="px-4 py-3 border-t border-gray-700">
//                       <p className="text-gray-300 text-sm">
//                         Analytical cookies are used to understand how visitors interact with the website. These cookies help provide 
//                         information on metrics such as the number of visitors, bounce rate, traffic source, etc.
//                       </p>
//                     </div>
//                   )}
//                 </div>

//                 {/* Performance */}
//                 <div className="border border-gray-700 rounded-lg">
//                   <button
//                     onClick={() => toggleSection('performance')}
//                     className="w-full px-4 py-3 flex justify-between items-center"
//                   >
//                     <span className="font-medium">Performance</span>
//                     {expandedSections.performance ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
//                   </button>
//                   {expandedSections.performance && (
//                     <div className="px-4 py-3 border-t border-gray-700">
//                       <p className="text-gray-300 text-sm">
//                         Performance cookies are used to understand and analyze the key performance indexes of the website which helps 
//                         in delivering a better user experience for the visitors.
//                       </p>
//                     </div>
//                   )}
//                 </div>
//               </div>

//               {/* Action Buttons */}
//               <div className="flex flex-col sm:flex-row gap-3">
//                 <button
//                   onClick={() => handleSave('reject-all')}
//                   className="flex-1 px-4 py-2 border border-gray-600 rounded-md hover:bg-gray-800 transition-colors"
//                 >
//                   Reject All
//                 </button>
//                 <button
//                   onClick={() => handleSave('save-preferences')}
//                   className="flex-1 px-4 py-2 border border-gray-600 rounded-md hover:bg-gray-800 transition-colors"
//                 >
//                   Save My Preferences
//                 </button>
//                 <button
//                   onClick={() => handleSave('accept-all')}
//                   className="flex-1 px-4 py-2 bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
//                 >
//                   Accept All
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   )
// }


'use client'

import { useState, useEffect, useRef } from 'react'
import { X, Cookie, ChevronDown, ChevronUp } from 'lucide-react'
import Cookies from 'js-cookie'

export default function CookieConsent() {
  const [isOpen, setIsOpen] = useState(false)
  const [showSmallDiv, setShowSmallDiv] = useState(false)
  const [expandedSections, setExpandedSections] = useState({
    necessary: false,
    functional: false,
    analytics: false,
    performance: false
  })
  const [preferences, setPreferences] = useState({
    necessary: true, // Always true
    functional: false,
    analytics: false,
    performance: false
  })
  const [showFloatingButton, setShowFloatingButton] = useState(false)
  const modalRef = useRef(null)

  useEffect(() => {
    const cookiePreferences = Cookies.get('cookiePreferences')
    if (!cookiePreferences) {
      setShowSmallDiv(true)
    } else {
      setShowFloatingButton(true)
      setPreferences(JSON.parse(cookiePreferences))
    }

    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }))
  }

  const handleSave = (type) => {
    let newPreferences = { ...preferences }
    
    if (type === 'accept-all') {
      newPreferences = {
        necessary: true,
        functional: true,
        analytics: true,
        performance: true
      }
    } else if (type === 'reject-all') {
      newPreferences = {
        necessary: true,
        functional: false,
        analytics: false,
        performance: false
      }
    }

    // Save preferences to an actual cookie
    Cookies.set('cookiePreferences', JSON.stringify(newPreferences), { expires: 365 })
    setPreferences(newPreferences)
    setIsOpen(false)
    setShowSmallDiv(false)
    setShowFloatingButton(true)

    // Set or remove actual cookies based on preferences
    if (newPreferences.functional) {
      Cookies.set('functional_cookie', 'true', { expires: 365 })
    } else {
      Cookies.remove('functional_cookie')
    }

    if (newPreferences.analytics) {
      Cookies.set('analytics_cookie', 'true', { expires: 365 })
    } else {
      Cookies.remove('analytics_cookie')
    }

    if (newPreferences.performance) {
      Cookies.set('performance_cookie', 'true', { expires: 365 })
    } else {
      Cookies.remove('performance_cookie')
    }
  }

  return (
    <>
      {/* Small Cookie Div */}
      {showSmallDiv && (
        <div className="fixed bottom-4 right-4 bg-gray-900 text-white p-4 rounded-lg shadow-lg max-w-sm z-50">
          <p className="text-sm mb-2">
            We use cookies to enhance your browsing experience and analyze our traffic.
          </p>
          <p className="text-sm mb-4">
            By clicking "Accept All", you consent to our use of cookies.
          </p>
          <div className="flex flex-col sm:flex-row gap-2">
            <button
              onClick={() => handleSave('reject-all')}
              className="px-3 py-1 text-sm border border-gray-600 rounded-md hover:bg-gray-800 transition-colors"
            >
              Reject All
            </button>
            <button
              onClick={() => {
                setIsOpen(true)
                setShowSmallDiv(false)
              }}
              className="px-3 py-1 text-sm border border-gray-600 rounded-md hover:bg-gray-800 transition-colors"
            >
              Preferences
            </button>
            <button
              onClick={() => handleSave('accept-all')}
              className="px-3 py-1 text-sm bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
            >
              Accept All
            </button>
          </div>
        </div>
      )}

      {/* Floating Cookie Button */}
      {showFloatingButton && !isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          className="fixed bottom-4 left-4 p-3 bg-gray-900 text-white rounded-full shadow-lg hover:bg-gray-800 transition-colors"
          aria-label="Cookie Settings"
        >
          <Cookie className="w-6 h-6" />
        </button>
      )}

      {/* Cookie Consent Modal */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div 
            ref={modalRef}
            className="bg-gray-900 text-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto"
          >
            <div className="p-6 space-y-6">
              {/* Header */}
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">Customize Consent Preferences</h2>
                <button
                  onClick={() => setIsOpen(false)}
                  className="text-gray-400 hover:text-white transition-colors"
                  aria-label="Close"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>

              {/* Description */}
              <p className="text-gray-300">
                We use cookies to help you navigate efficiently and perform certain functions. 
                You will find detailed information about all cookies under each consent category below.
              </p>

              {/* Cookie Sections */}
              <div className="space-y-4">
                {/* Necessary */}
                <div className="border border-gray-700 rounded-lg">
                  <button
                    onClick={() => toggleSection('necessary')}
                    className="w-full px-4 py-3 flex justify-between items-center"
                  >
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Necessary</span>
                      <span className="text-sm text-green-400">Always Active</span>
                    </div>
                    {expandedSections.necessary ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
                  </button>
                  {expandedSections.necessary && (
                    <div className="px-4 py-3 border-t border-gray-700">
                      <p className="text-gray-300 text-sm">
                        Necessary cookies are required to enable the basic features of this site, such as providing secure log-in 
                        or adjusting your consent preferences. These cookies do not store any personally identifiable data.
                      </p>
                    </div>
                  )}
                </div>

                {/* Functional */}
                <div className="border border-gray-700 rounded-lg">
                  <button
                    onClick={() => toggleSection('functional')}
                    className="w-full px-4 py-3 flex justify-between items-center"
                  >
                    <span className="font-medium">Functional</span>
                    {expandedSections.functional ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
                  </button>
                  {expandedSections.functional && (
                    <div className="px-4 py-3 border-t border-gray-700">
                      <p className="text-gray-300 text-sm">
                        Functional cookies help perform certain functionalities like sharing the content of the website on social media 
                        platforms, collecting feedback, and other third-party features.
                      </p>
                    </div>
                  )}
                </div>

                {/* Analytics */}
                <div className="border border-gray-700 rounded-lg">
                  <button
                    onClick={() => toggleSection('analytics')}
                    className="w-full px-4 py-3 flex justify-between items-center"
                  >
                    <span className="font-medium">Analytics</span>
                    {expandedSections.analytics ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
                  </button>
                  {expandedSections.analytics && (
                    <div className="px-4 py-3 border-t border-gray-700">
                      <p className="text-gray-300 text-sm">
                        Analytical cookies are used to understand how visitors interact with the website. These cookies help provide 
                        information on metrics such as the number of visitors, bounce rate, traffic source, etc.
                      </p>
                    </div>
                  )}
                </div>

                {/* Performance */}
                <div className="border border-gray-700 rounded-lg">
                  <button
                    onClick={() => toggleSection('performance')}
                    className="w-full px-4 py-3 flex justify-between items-center"
                  >
                    <span className="font-medium">Performance</span>
                    {expandedSections.performance ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
                  </button>
                  {expandedSections.performance && (
                    <div className="px-4 py-3 border-t border-gray-700">
                      <p className="text-gray-300 text-sm">
                        Performance cookies are used to understand and analyze the key performance indexes of the website which helps 
                        in delivering a better user experience for the visitors.
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex flex-col sm:flex-row gap-3">
                <button
                  onClick={() => handleSave('reject-all')}
                  className="flex-1 px-4 py-2 border border-gray-600 rounded-md hover:bg-gray-800 transition-colors"
                >
                  Reject All
                </button>
                <button
                  onClick={() => handleSave('save-preferences')}
                  className="flex-1 px-4 py-2 border border-gray-600 rounded-md hover:bg-gray-800 transition-colors"
                >
                  Save My Preferences
                </button>
                <button
                  onClick={() => handleSave('accept-all')}
                  className="flex-1 px-4 py-2 bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
                >
                  Accept All
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

