import React, { useEffect, useState } from "react";
import Examples from "./Examples";
import Testimonials from "./Testimonials";
import { Link } from "react-router-dom";
import CookieConsent from "./components/CookiesConcent";
import Item4 from "./assets/item4.png";

const testimonials = [
  {
    id: 1,
    name: "Erez Marchini",
    position: "CEO at Goupss",
    image: "/placeholder.svg?height=400&width=300",
    quote:
      "We generate content for our clients on a weekly basis, and working with Fluffy has made things a lot easier for us. Their AI tools help us put out higher quality content way faster than we could before, and the platform is very straightforward to use. Next gen tool for content creation at any scale!",
  },
  {
    id: 2,
    name: "Albert Bitton",
    position: "Event Organizer, Berlin",
    image: "/placeholder.svg?height=400&width=300",
    quote:
      "As I am expanding the areas of my work life, time are filled up with a lot of tasks that require me to look for a solution to clear time and still be efficient with promoting my services in social media. I used Fluffy to generate content, it used text and photos from my past content and generated great new content for me. Saving me a lot of time.",
  },
  {
    id: 3,
    name: "Jane Doe",
    position: "Marketing Director, XYZ Corp",
    image: "/placeholder.svg?height=400&width=300",
    quote:
      "Fluffy has revolutionized our content creation process. The AI-generated content is incredibly human-like, and our clients can't tell the difference. It's like having an extra team member dedicated to producing high-quality content around the clock.",
  },
];

export default function LandingPage() {
  const faqData = [
    {
      question: "What is Fluffy, and how does it work?",
      answer:
        "Fluffy is an AI-based platform by SyncTank that automates personalized content creation across platforms, including social media, email, and WhatsApp. It analyzes user data and tailors messaging to individual reader preferences, ensuring a unique voice across multiple platforms.",
    },
    {
      question: "What sets Fluffy apart from other AI content tools?",
      answer:
        "Fluffy’s combination of social modeling and focus on emotional relevance, rather than solely keyword optimization, creates content that resonates on a deeper level, driving meaningful user engagement.",
    },
    {
      question: "How does Fluffy automate personalized content?",
      answer:
        "Fluffy leverages algorithms that analyze demographic data, past interactions, and personality cues to generate unique content tailored to each audience segment, blending the brand's voice with the user’s interests and preferences.",
    },
    {
      question: "What does social modeling mean in Fluffy’s content creation?",
      answer:
        "Fluffy’s social modeling approach means it considers emotions, context, and focus to align content with user interests, making interactions feel personal and meaningful. It essentially 'chooses words' that resonate emotionally and contextually with the target audience.",
    },
    {
      question: "How does Fluffy detect and use trending topics?",
      answer:
        "Through live social media scans, Fluffy identifies real-time trends within relevant industries, analyzing high-engagement topics and incorporating these themes into content. This keeps your messaging fresh and topical.",
    },
    {
      question: "What is Fluffy’s ‘Emotional Sensitivity’ feature?",
      answer:
        "Fluffy’s Emotional Sensitivity feature assesses dominant emotions in the user interactions, images, and videos, such as excitement or nostalgia, and crafts content that connects with those emotions to enhance engagement.",
    },
    {
      question: "Can Fluffy integrate with scheduling tools?",
      answer:
        "While Fluffy doesn’t publish content directly, it can integrate seamlessly with tools like Zapier, Tailwind, and Make. This allows for smooth scheduling and streamlining within your existing workflow.",
    },
    {
      question: "Who benefits most from using Fluffy?",
      answer:
        "Marketing agencies, social media managers, and content creators who need scalable yet personal content across various channels find Fluffy invaluable for maintaining a consistent, engaging voice.",
    },
  ];

  const challenges = [
    {
      title: "Time Management:",
      description:
        "Creating original content while managing multiple client relationships is overwhelming.",
    },
    {
      title: "Consistency:",
      description:
        "It's hard to maintain required consistency of posts and replies without using AI",
    },
    {
      title: "Creativity:",
      description:
        "Generating fresh, authentic content that fits each client's brand voice takes time",
    },
    {
      title: "Client Expectations:",
      description:
        "Meeting diverse client needs without compromising on quality is tough",
    },
    {
      title: "Engagement:",
      description:
        "Connecting with audience in a genuine, engaging way requires presence and a unique voice",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleLinkClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const headerOffset = 80; // Adjust this value based on your header height
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    setMenuOpen(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="font-sans bg-[#2559EC] text-[#09091D]">
      <div className="overflow-hidden ">
        <header className="container mx-auto text-white px-4 py-6 flex justify-between items-center">
          <img src="flufflogo.png" alt="" className="w-[114px] h-[54px]" />
          <nav
            className={`${
              menuOpen ? "flex" : "hidden"
            } md:flex flex-col md:flex-row absolute md:relative md:top-0 top-16 left-0 right-0 bg-blue-600 md:bg-transparent z-50 md:space-x-6 space-y-4 md:space-y-0 p-4 md:p-0`}
          >
            <Link to={"/individuals"} className="hover:text-gray-200">
              For Individuals
            </Link>
            <a
              href="https://chromewebstore.google.com/detail/fluffy-comment-drafter/ihicjdifnmmccimpnaggfanhhbgjnako"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-200 hover:underline"
            >
              Comment Drafter
            </a>

            <a
              href="#About"
              className="hover:text-gray-200"
              onClick={(e) => handleLinkClick(e, "About")}
            >
              About
            </a>
            <a
              href="#Features"
              className="hover:text-gray-200"
              onClick={(e) => handleLinkClick(e, "Features")}
            >
              Features
            </a>
            <a
              href="#FAQ"
              className="hover:text-gray-200"
              onClick={(e) => handleLinkClick(e, "FAQ")}
            >
              FAQ
            </a>
          </nav>
          <div className="flex items-center space-x-4">
            {/* <button className="hover:text-gray-200 hidden md:block">Login</button> */}

            <a
              href="https://app.fluffy-ai.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className=" text-blue-600 bg-white px-4 py-2 rounded-full  hidden md:block border-white border hover:-translate-y-1 animate-pulse">
                Go to App
              </button>
            </a>

            <button
              className="md:hidden"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? "Close" : "Menu"}
            </button>
          </div>
        </header>

        <main
          className="container mx-auto px-4 mt-6 md:mt-16 text-white  text-center"
          style={{
            backgroundImage: `url('/heroBG.svg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 className="text-[32px] sm:text-[44px] md:text-[60px]  font-bold  mb-7 bg-gradient-to-r from-white from-40% to-[#a0b0fc] to-100% bg-clip-text text-transparent">
            We build your custom content generator
          </h1>
          <p className="text-lg md:text-xl my-6 pb-4">
            Personalize your social content at scale
          </p>
          <a
            href="https://form.jotform.com/242984708982474"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-[#111827] text-white px-6 py-3 mt-2 mb-3 rounded-full text-base md:text-lg hover:bg-gray-800">
              Apply For a Free Demo
            </button>
          </a>
          <div className="relative mt-12 md:mt-16">
            <img
              src="heroimg.svg"
              alt="Fluffy Dashboard"
              className="w-full max-w-6xl mx-auto rounded-t-lg"
            />

            <div className="absolute hidden md:block right-0 md:right-24 top-1/3 transform translate-x-1/2 -translate-y-1/2 -space-y-12 md:-space-y-24 scale-75 md:scale-100">
              <img
                src="item1.png"
                alt="Fluffy Dashboard"
                className="w-48 md:w-64 h-32 md:h-44"
              />
              <img
                src="item2.png"
                alt="Fluffy Dashboard"
                className="w-48 md:w-64 h-32 md:h-44"
              />
              <img
                src="item3.png"
                alt="Fluffy Dashboard"
                className="w-48 md:w-64 h-32 md:h-44"
              />
              <img
                src={Item4}
                alt="Fluffy Dashboard"
                className="w-48 md:w-64 h-30 md:h-40"
              />
            </div>
          </div>
        </main>
      </div>
      <section className="pt-16 md:pt-20 px-4 md:px-[10%] lg:px-[20%] text-center w-full bg-white text-black">
        <p className="text-2xl sm:text-3xl md:text-5xl leading-[28px] sm:leading-12 font-semibold">
          Use Our{" "}
          <span className=" bg-gradient-to-r from-[#2559EC] to-[#7388F7] bg-clip-text text-transparent">
            Custom-Made Pipeline
          </span>{" "}
          To Streamline Your Content Production
        </p>
        <p className="text-lg md:text-[22px] mb-0 mt-6 md:mt-10">
          As a marketing professional, you understand the importance of
          efficiency while delivering high-quality results across multiple
          clients. Juggling the demands of creating compelling content and
          managing ongoing tasks can often feel overwhelming. That’s where we
          come in.
        </p>
      </section>

      <section className="flex flex-col md:flex-row px-4 md:px-[10%] bg-white justify-between items-center">
        <div className="flex-1 md:ml-[10%] mb-8 md:mb-0 ">
          <p className="text-3xl text-left leading-[50px] md:text-[48px] bg-gradient-to-r from-[#2559EC] to-[#7388F7] bg-clip-text text-transparent mb-6 md:mb-10 font-semibold">
            Automate Content Personalization
          </p>
          <p className="mt-8 text-left">
            We all know personalized content works better than templates, but
            creating it can be{" "}
            <span className="text-[#2559EC] font-bold">time-consuming</span>.
            Not anymore! Our team of engineers and marketing experts will learn
            your data and create a dedicated version of the Fluffy algorithm
            that aligns with your brand voice which you can use{" "}
            <span className="text-[#2559EC] font-bold">
              across your channels
            </span>{" "}
            (social media, WhatsApp, email, etc). Our algorithm tool is the{" "}
            <span className="text-[#2559EC] font-bold">first of its kind</span>{" "}
            to provide an end-to-end content solution with{" "}
            <span className="text-[#2559EC] font-bold">
              built-in image processing
            </span>
            , meaning it takes your image's visual features automatically into
            account. s It{" "}
            <span className="text-[#2559EC] font-bold">tailors</span> posts to
            each reader's preferences, personality, and demographics while{" "}
            <span className="text-[#2559EC] font-bold">matching</span> the
            writer’s voice, using relevant data to ensure each message hits the
            mark!
          </p>
          {/* <p className='text-justify'>While working closely with marketers, we identified 5 key challenges of handling client content creation:</p>
            <ul className="space-y-4 mt-4">
              {challenges.map((challenge, index) => (
                <li key={index} className="flex min-h-6 items-start">
                  <svg width="24" height="24" viewBox="0 0 24 24" className="w-6 h-6 mr-2 mt-1 flex-shrink-0">
                    <path fill="#4ade80" d="M8,5 L19,12 L8,19 Z" />
                  </svg>
                  <div>
                    <span className="font-bold">{challenge.title}</span>{" "}
                    <span>{challenge.description}</span>
                  </div>
                </li>
              ))}
            </ul>
            <p className="mt-4">If these challenges sound familiar, you're not alone 🤗!</p> */}
        </div>
        <img
          src="frame2.png"
          alt=""
          className="flex-1 w-full max-w-full md:max-w-[50%]"
        />
      </section>

      <section className="flex flex-col items-center px-4 md:px-[10%] lg:px-[15%] w-full gap-6 md:gap-10 bg-white pb-20">
        <p className="text-3xl md:text-[48px] leading-[55px] bg-gradient-to-r from-[#2559EC] to-[#7388F7] bg-clip-text text-transparent w-full text-center">
          How we’re different
        </p>
        <p className="text-center md:px-[10%]">
          Unlike any other service, our tools apply a layer of social and visual
          modeling to optimize the content to your audience. Our algorithms are
          being constantly trained on live trends to keep them relevant and make
          sure they pick the right words to create a meaningful connection with
          your audience.
        </p>
        {/* <ul className="space-y-3 w-full justify-start">
          {challenges.map((challenge, index) => (
            <li key={index} className="flex items-start w-full mb-4">
              <svg width="24" height="24" viewBox="0 0 24 24" className="w-6 h-6 mr-2 mt-1 flex-shrink-0">
                <path fill="#4ade80" d="M8,5 L19,12 L8,19 Z" />
              </svg>
              <div>
                <span className="font-bold">{challenge.title}</span>{" "}
                <span>{challenge.description}</span>
              </div>
            </li>
          ))}
        </ul> */}
        <img
          src="frame3.png"
          alt=""
          className="max-w-full md:max-w-[700px] max-h-[700px]"
        />
      </section>

      <section className="text-center pb-12 md:py-5 bg-white px-4 md:px-[20%]">
        <p className="text-3xl md:text-[48px] leading-[55px] bg-gradient-to-r from-[#2559EC] to-[#7388F7] bg-clip-text text-transparent">
          Features
        </p>
        <p id="Features" className="mt-5">
          Fluffy offers a suite of tools designed to streamline your marketing
          processes
        </p>
      </section>

      <section className="flex flex-wrap px-4 pt-5 md:px-[5%] lg:px-[10%] gap-6 md:gap-10 bg-white pb-20">
        <div className="flex p-4 md:p-5 w-full md:max-w-[45%]  bg-[#F5F5FF] rounded-xl  items-center">
          <div className="p-4 md:p-5">
            <div className="flex gap-4 items-center">
              <img
                src="frame4icon4.png"
                alt=""
                className="w-12 h-12 md:w-[72px] md:h-[72px]"
              />
              <p className="text-xl sm:text-2xl lg:text-3xl ">
                Customization for You
              </p>
            </div>
            <p className="text-left mt-4 md:mt-8">
              As an Enterprise client, our AI engineers will work closely with
              you, understanding your unique needs and build custom-made
              features to make sure you get the EXACT results you request,
              formatted to your specifications, followed by our ongoing
              maintainance and optimization.
            </p>
          </div>
        </div>

        <div className="flex p-4 md:p-5 w-full md:max-w-[45%] bg-[#F5F5FF] rounded-xl items-center">
          <div className="p-4 md:p-5">
            <div className="flex gap-4 items-center ">
              <img
                src="frame4icon1.png"
                alt=""
                className="w-12 h-12 md:w-[72px] md:h-[72px]"
              />
              <p className="text-xl sm:text-2xl lg:text-3xl ">
                Auto-Personalization
              </p>
            </div>
            <p className="text-left mt-4 md:mt-8">
              Our algorithms personalize content to the audience by analyzing
              the writer's intent and reader's public data, creating customized
              posts that feel relevant and engaging for each individual.
            </p>
          </div>
        </div>

        <div className="flex p-4 md:p-5 w-full md:w-[45%] bg-[#F5F5FF] rounded-xl  items-center">
          <div className="p-4 md:p-5">
            <div className="flex gap-4 items-center ">
              <img
                src="frame4icon2.png"
                alt=""
                className="w-12 h-12 md:w-[72px] md:h-[72px]"
              />
              <p className="text-xl sm:text-2xl lg:text-3xl">Trend Tracking</p>
            </div>
            <p className="text-left mt-4 md:mt-8">
              Scanning social media posts from related channels in live time and
              identifying key trending topics that are most interesting to write
              about now.
            </p>
          </div>
        </div>

        <div className="flex p-4 md:p-5 w-full md:w-[45%] bg-[#F5F5FF] rounded-xl items-center">
          <div className="p-4 md:p-5">
            <div className="flex gap-4 items-center ">
              <img
                src="frame4icon3.png"
                alt=""
                className="w-12 h-12 md:w-[72px] md:h-[72px]"
              />
              <p className="text-xl sm:text-2xl lg:text-3xl">
                Emotional Sensitivity
              </p>
            </div>
            <p className="text-left mt-4 md:mt-8">
              Identifying the dominant emotions in relevant text and media and
              use them as writing hooks.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-white overflow-x-hidden ">
        <Examples />
      </section>

      <section className="bg-white">
        <Testimonials />
      </section>

      <div className="flex flex-col md:flex-row px-4 md:px-[10%] bg-white py-8 items-center">
        <div className="w-full md:w-1/2 md:mr-12 mb-8 md:mb-0 text-left">
          <p className="text-3xl md:text-[48px] leading-[55px] bg-gradient-to-r from-[#2559EC] to-[#7388F7] bg-clip-text text-transparent mb-6">
            Why You Need Fluffy for Marketers
          </p>
          <div className="mt-3 flex gap-2 items-center">
            {" "}
            <img src="/Frame.svg" alt="" width={16} height={16} />{" "}
            <p>Does your team overspend time and energy on content creation?</p>
          </div>
          <div className="mt-3 flex gap-2 items-center">
            {" "}
            <img src="/Frame.svg" alt="" width={16} height={16} />{" "}
            <p>
              Constantly changing trends that are impossible to stay on top of?
            </p>
          </div>
          <div className="mt-3 flex gap-2 items-center">
            {" "}
            <img src="/Frame.svg" alt="" width={16} height={16} />{" "}
            <p>
              Looking to improve the engagement and inbound leads of your
              content?
            </p>
          </div>
          <p className="mt-3">
            Our service is designed specifically for marketers and agencies to
            automate quality content generation. We will build your custom
            GenAI-powered pipeline that ensures your content fosters engagement
            and connection with the target audience. Fluffy‘s content is
            optimized for engagement and organic traffic generation, freeing you
            up to focus on the bigger picture.
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <img src="frame5.png" alt="" className="max-h-[625px] max-w-full" />
        </div>
      </div>

      <div
        id="Pricing"
        className="flex flex-col md:flex-row px-4 md:px-[10%] bg-[#2559EC] py-8 items-center text-white"
      >
        <img
          src="frame6.png"
          alt=""
          className=" max-h-[600px] w-full max-w-full md:max-w-[50%] mb-8 md:mb-0"
        />
        <div className="md:ml-12 text-left">
          <p className="text-3xl md:text-[48px] leading-[55px] mb-4">
            What Fluffy is Not
          </p>
          <p className="text-justify">
            We automate the production of the content itself. We don’t automate
            its publication. Fluffy can integrate with your preferred automation
            tool (Zapier, Make, Tailwind, etc’), and stream content into your
            campaign scheduler.
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row px-4 md:px-[10%] bg-white py-8 items-center">
        <div className="w-full md:w-1/2 mb-8 md:mb-0 text-left">
          <p className="text-3xl md:text-[48px] bg-gradient-to-r leading-[55px]  from-[#2559EC] to-[#7388F7] bg-clip-text text-transparent mb-4">
            Work with Us
          </p>
          <p className="mt-4">
            Due to increasing demand, we are unable to consider all businesses
            for our service. If you think this service is for you, are welcome
            to apply here or try our generic app. We offer{" "}
            <span className="text-[#2559EC] font-bold">competitive prices</span>{" "}
            for small businesses and agencies.
          </p>
          <p className="mt-4">
            If selected, we’ll schedule a{" "}
            <span className="text-[#2559EC] font-bold">
              free demo & consultation call
            </span>{" "}
            to discuss your agency’s challenges and explore how Fluffy can
            integrate into your workflow to simplify your operations and elevate
            your content quality.
          </p>
          <a
            href="https://form.jotform.com/242984708982474"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="h-[52px] bg-[#2559EC] rounded-full px-8 text-white mt-5">
              Apply Here
            </button>
          </a>
        </div>
        <img src="frame7.png" alt="" className="max-w-full md:max-w-[50%]" />
      </div>

      <div
        id="About"
        className="flex flex-col md:flex-row px-4 md:px-[10%] bg-white py-8 items-center"
      >
        <div className="md:mr-5 text-left">
          <p className="text-3xl md:text-[48px] leading-[55px]  bg-gradient-to-r from-[#2559EC] to-[#7388F7] bg-clip-text text-transparent mb-4">
            Who We Are
          </p>
          <p className="text-justify mt-4">
            SyncTank is a leader in AI-driven solutions, helping businesses
            enhance client interactions and operational efficiency. With a focus
            on innovation and customer satisfaction, SyncTank has become a
            trusted partner for companies looking to leverage AI for a
            competitive edge.
          </p>
          <a
            href="https://synctankai.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="h-[52px] bg-[#2559EC] rounded-full px-8 text-white mt-5">
              Explore SyncTankAI
            </button>
          </a>
        </div>
        <img
          src="frame9.png"
          alt=""
          className="max-w-full md:max-w-[50%] mb-8 md:mb-0"
        />
      </div>

      <section id="FAQ" className="bg-white py-16 md:py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-[64px] leading-[65px]  font-bold text-center mb-8 md:mb-12 text-blue-500">
            Frequently Asked Questions
          </h2>
          <div className="max-w-3xl mx-auto">
            {faqData.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                isOpen={openIndex === index}
                onToggle={() => handleToggle(index)}
              />
            ))}
          </div>
        </div>
      </section>

      <section
        className="bg-blue-600 text-white py-16 md:py-20 px-4"
        style={{
          backgroundImage: `url('/contactimg.svg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl md:text-[64px] leading-[65px]  font-bold mb-4">
            Have More Questions?
          </h2>
          <p className="text-lg md:text-xl mb-8">
            Want to Join Us or to Collaborate? Feel free to send us an email.
          </p>
          <button className="bg-black text-white px-6 md:px-8 py-3 rounded-2xl text-base md:text-lg font-semibold">
            Contact us
          </button>
        </div>
      </section>

      <footer className="bg-[#0a0a23] text-white py-12">
        <div className="container mx-auto px-4 sm:px-10 md:px-24 lg:px-40">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <img
              src="flufflogo.png"
              alt=""
              className="w-[114px] h-[54px] mx-auto md:mx-0"
            />
            <div className="text-center md:text-left">
              <p>synctankai.com</p>
              <p>contact@synctankai.com</p>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div className="text-left">
                <h3 className="font-bold mb-2">Quick Links</h3>
                <ul className="space-y-2">
                  <li>
                    <Link to="/individuals" className="hover:underline">
                      For Individuals
                    </Link>
                  </li>
                  <li>
                    <a href="#About" className="hover:underline">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="#Pricing" className="hover:underline">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a href="#Features" className="hover:underline">
                      Features
                    </a>
                  </li>
                  <li>
                    <a href="#FAQ" className="hover:underline">
                      FAQs
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="font-bold mb-2">Legal</h3>
                <ul className="space-y-2">
                  <li>
                    <a href="#" className="hover:underline">
                      Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <Link to="/policy" className="hover:underline">
                      Privacy policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-8 border-gray-700" />
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm">&copy; Synctankai. All rights reserved.</p>
            <div className="flex space-x-4 mt-4 md:mt-0">
              <a href="#" className="hover:text-gray-400">
                <span className="sr-only">Facebook</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a href="#" className="hover:text-gray-400">
                <span className="sr-only">Twitter</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
              <a href="#" className="hover:text-gray-400">
                <span className="sr-only">Instagram</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a href="#" className="hover:text-gray-400">
                <span className="sr-only">RSS</span>
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
      <CookieConsent />
    </div>
  );
}

function FAQItem({ question, answer, isOpen, onToggle }) {
  return (
    <div className="border-b border-gray-200">
      <button
        className="flex justify-between items-center w-full py-5 text-left"
        onClick={onToggle}
      >
        <span className="text-base md:text-lg font-semibold text-[#2559EC]">
          {question}
        </span>
        <svg
          className={`w-6 h-6 transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="#3b82f6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="pb-5 text-gray-600 text-justify">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
}
