import React from 'react'
import AiToolsPng from '../assets/Aitools.png'
import ArrowVector from '../assets/ArrowVector.png'
function Aitools() {
  return (
    <div>
        
       <section className='flex flex-col md:flex-row px-4 md:px-[5%] lg:px-[10%] pt-6 sm:pt-16 md:pt-24 bg-white justify-between text-left items-center '>
      
       <div className="space-y-6 my-14">
       <h2 className="text-2xl sm:text-3xl  lg:text-5xl font-semibold  mb-12 text-[#2559EC]">
          Stay ahead of the Social Media Game With Our AI Tools
        </h2>
        <div className='flex flex-row space-x-4'>
        {/* <div class="w-0 h-0 
  border-t-[10px] border-t-transparent
  border-l-[15px] border-l-green-500
  border-b-[10px] border-b-transparent">
</div> */}
<img src={ArrowVector} alt="" className='h-1/2 mt-2' />
<p className="">
              Whether you are a content creator or a small business owner, maintaining social media presence is as demanding as it is crucial.
            </p>
        </div>
        <div className='flex flex-row space-x-4'>
        <img src={ArrowVector} alt="" className='h-1/2 mt-2' />

            <p className="">
              We all want to stay ahead of the game, but the work can sometimes be overwhelming.
            </p>
            </div>
            <div className='flex flex-row space-x-4'>
            <img src={ArrowVector} alt="" className='h-1/2 mt-2' />

            <p className="">
              Be it making text overlays for your videos, captions for your images, or coming up with writing ideas that match current trends.
            </p>
            </div>
            <div className='flex flex-row space-x-4'>
            <img src={ArrowVector} alt="" className='h-1/2 mt-2' />

            <p className="">
              Even a week of inactivity can lead to a drop in engagement, and social media managers don't always get our brand's message the way we do.
            </p>
            </div>
            <div className='flex flex-row space-x-4'>
            <img src={ArrowVector} alt="" className='h-1/2 mt-2' />

            <p className=" font-semibold">
              This is where Fluffy steps in.
            </p>
            </div>
          </div>
        <img src={AiToolsPng} alt="" className='flex-1 items-center align-center md:max-w-[50%] w-full max-h-[850px]  ' />
      </section>
    </div>
  )
}

export default Aitools
