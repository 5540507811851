import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import LandingPage from './LandingPage';
import Thankyou from './thankyou';
import Indivisuals from './pages/Indivisuals';
import PrivacyPolicy from './pages/PrivacyPolicy';
function App() {
  return (
    <Router>
      <div className="App">
        {/* Add navigation here if you want links to different routes */}
        <Routes>
          <Route path="/" element={<Navigate to="/individuals" replace />} />
          <Route path="/individual" element={<Navigate to="/individuals" replace />} />
          <Route path="/individuals" element={< Indivisuals/>} />
          <Route path="/marketers" element={<LandingPage />} />
          <Route path="/marketing-thanks" element={<Thankyou />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;