import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Aitools from '../components/Aitools';
import OurLearning from '../components/OurLearning';
import Features from '../components/Features';
import Pricing from '../components/Pricing';
import HowWeAreDifferent from '../components/HowWeAreDifferent';
import Remarks from '../components/Remarks';
import WhoWeAre from '../components/WhoWeAre';
import Questions from '../components/Questions';
import Footer from '../components/Footer';
import CookieConsent from '../components/CookiesConcent';

function App() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []); 
  
  return (
  <div className="font-sans bg-[#2559EC] text-[#09091D]">
   
   <CookieConsent />
    {/* <Navbar /> */}
   <Hero/>
   <Aitools />
   <OurLearning/>
   <Features/>
   <Pricing/>
   <HowWeAreDifferent/>
   <Remarks />
   <WhoWeAre/>
   <Questions/>
   <Footer/>
  </div>
  );
}

export default App;
