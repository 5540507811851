import React from "react";
import OurLearningPng from "../assets/OurLearning.png";
import ArrowVector from "../assets/ArrowVector.png";

function OurLearning() {
  return (
    <div className="bg-white">
      <section className="flex flex-col md:flex-row px-4 md:px-12 lg:px-[5%] bg-[#F5F5FF] justify-between py-16 text-left items-center rounded-t-3xl ">
        <img
          src={OurLearningPng}
          alt=""
          className="flex-1 max-w-full w-full md:max-w-[50%] max-h-[400px] md:max-h-[524px] lg:max-h-[800px]"
        />
        <div className="space-y-6 my-14 md:p-6 lg:p-12">
          <h2 className="text-2xl sm:text-3xl  lg:text-4xl font-semibold  mb-6 text-[#2559EC]">
            What we learned from our clients, small business owners
          </h2>
          <p className="font-bold">The problems we solve:</p>
          <div className="flex flex-row space-x-4">
            <img src={ArrowVector} alt="" className="h-1/2 mt-2" />
            <p className="">
              <span className="font-semibold">Promptimization</span> Our AI is
              constantly optimized to match trending topics and styles, so you
              don’t need to break your back over trying to figure out the best
              prompts every time.
            </p>
          </div>
          <div className="flex flex-row space-x-4">
            <img src={ArrowVector} alt="" className="h-1/2 mt-2" />
            <p className="">
              <span className="font-semibold">Limited time:</span> Spending time
              on social media instead of focusing on your product or service
              doesn't feel right.
            </p>
          </div>
          <div className="flex flex-row space-x-4">
            <img src={ArrowVector} alt="" className="h-1/2 mt-2" />
            <p className="">
              <span className="font-semibold">Staying consistent:</span> Staying
              consistent on social media is hard when your focus is on daily
              cash flow needs.
            </p>
          </div>
          <div className="flex flex-row space-x-4">
            <img src={ArrowVector} alt="" className="h-1/2 mt-2" />
            <p className="">
              <span className="font-semibold">Not knowing what to post:</span>{" "}
              Creating content that feels genuine, and not forced, contrived or
              salesy, is a big challenge.
            </p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="font-bold">
              If you feel any of the above -{" "}
              <span className="font-semibold text-[#2559EC]">
                don’t worry, you’re not alone!
              </span>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OurLearning;
