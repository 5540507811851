import React from "react";
import HeroPng from "../assets/post-generation.png";
import HeroItem1 from "../assets/hero Media.png";
import Navbar from "./Navbar";
import Heroitem4 from "../assets/item4.png";

function Hero() {
  return (
    <div className=" overflow-hidden ">
      <Navbar />
      <main
        className="container mx-auto px-4 mt-6 md:mt-16 text-white text-center"
        style={{
          backgroundImage: `url('/heroBG.svg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1 className="text-6xl md:text-[96px] max-w-96 mx-auto pb-5 font-semibold bg-gradient-to-r from-white  from-30% to-[#879bfd] to-100% bg-clip-text text-transparent">
          Fluffy
        </h1>
        <p className="text-lg md:text-xl mt-6 mb-5">
          Keep your brand relevant without the hassle
        </p>
        <a
          href="https://app.fluffy-ai.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="bg-[#111827] text-white px-6 py-3 mt-6 rounded-full text-base md:text-lg hover:bg-gray-800">
            Start Using Our App
          </button>
        </a>
        <div className="relative mt-12 md:mt-14">
          <img
            src={HeroPng}
            alt="Fluffy Dashboard"
            className="w-full max-w-6xl mx-auto rounded-t-lg"
          />

          <div className="absolute hidden lg:block right-0 2xl:right-24 top-1/3 transform translate-x-1/2 -translate-y-1/2 -space-y-12 md:-space-y-24 scale-75 md:scale-100">
            <img
              src={HeroItem1}
              alt="Fluffy Dashboard"
              className="w-48 md:w-64 h-32 md:h-44"
            />
            <img
              src="item2.png"
              alt="Fluffy Dashboard"
              className="w-48 md:w-64 h-32 md:h-44"
            />
            <img
              src="item3.png"
              alt="Fluffy Dashboard"
              className="w-48 md:w-64 h-32 md:h-44"
            />
            <img
              src={Heroitem4}
              alt="Fluffy Dashboard"
              className="w-48 md:w-64 h-30 md:h-40"
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export default Hero;
