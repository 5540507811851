import React, { useState, useEffect, useRef, useCallback } from "react";

const posts = [
  {
    id: 1,
    image: "/black-jacket.png",
    title: "Post #1",
    content: `Confidence is silent, outfits are not 🍀<br>#fashioninspo #streetstyle #leatherjacket`,
  },
  {
    id: 2,
    image: "/img2.png",
    title: "Post #1",
    content: `Feeling serene and free 🌿<br>Intricate lace and bohemian flair in earthy tones ✨<br><br>#bohochic #fashioninspiration #bohemianstyle`,
  },
  {
    id: 3,
    image: "/img3.png",
    title: "Post #3",
    content: `This dress is a riot of color and attitude<br><br>Bold patterns crash together like a symphony of chaos. Each stitch tells a story of courage and flair that make a statement🌟<br><br>#fashion #style #colorfulfashion #ootd #fashioninspiration`,
  },
  {
    id: 4,
    image: "/img4.png",
    title: "Post #4",
    content: `Elevate your style with this sleek tailored coat in a light gray shade<br><br>Crafted from a luxurious wool blend, its minimalist design is all about understated elegance🪶<br><br>#fashioninspo #stylegoals #minimalistfashion`,
  },
  {
    id: 5,
    image: "/img5.png",
    title: "Post #3",
    content: `Brighten your look with this vibrant jacket 🌈<br><br>Bursting with bold patterns in orange, black, and white.<br><br>It’s lightweight with a cool ribbed collar and cuffs, perfect for any casual outing<br><br>#fashionstyle #ootd #statementjacket`,
  },
  {
    id: 6,
    image: "/img6.png",
    title: "Post #3",
    content: `Slip into this warm caramel leather jacket<br><br>It’s all about the sleek design and smooth texture that screams confidence<br><br>#fashiongoals #styleinspo #leatherjacket`,
  },
  {
    id: 7,
    image: "/img7.png",
    title: "Post #3",
    content: `Rolled-up sleeves never looked so effortless<br><br>This denim shirt gives off that laid-back vibe we all chase. It's all about the medium blue wash that pairs with everything. Perfect for those casual outdoor hangouts 🌿👕<br><br>I designed it with a touch of my personal style. Just the right blend of casual and polished.<br><br>#fashionstyle #denimlove #casualvibes`,
  },
  {
    id: 8,
    image: "/img8.png",
    title: "Post #3",
    content: `Get lost in the beauty of my nature-inspired bracelet. It's got a twisted vine-like design with tiny green gemstones that look like little leaves. Creating it was a journey of connecting with nature, and I had a blast working with the dark, antique-style metal<br><br>It's like wearing a bit of an enchanted forest on your wrist 🌿✨<br><br>#natureinspired #handmadejewelry #jewelrydesign #enchantedforest #bohojewelry`,
  },
  {
    id: 9,
    image: "/img9.png",
    title: "Post #3",
    content: `Copper vines tangled with green stones, that's what I've got for you<br><br>It's like nature and metal had a wild night and this beauty was born. Wear it if you dare to stand out 🌿✨<br><br>#handmadejewelry #natureinspired #uniquestyle`,
  },
  {
    id: 10,
    image: "/img10.png",
    title: "Post #3",
    content: `Elegance with a touch of sophistication<br><br>Perfect for those days when you want to feel a little extra fancy without the fuss 🌿💫<br><br>#handmadejewelry #ecochic #natureinspired`,
  },
  {
    id: 11,
    image: "/img11.png",
    title: "Post #3",
    content: `A ring that feels like a piece of modern art. I wanted to create something that feels both airy and sophisticated, like wearing a tiny sculpture. A little piece of my creative chaos🌪️<br><br>#jewelrydesign #modernart #fashionaccessories`,
  },
  {
    id: 12,
    image: "/img12.png",
    title: "Post #3",
    content: `You know I've got a thing for butterflies right? 🦋<br><br>This handcrafted brass beauty has wings so detailed you'd think it might fly away<br><br>#handmadejewelry #hairaccessories #elegantstyle`,
  },
  {
    id: 13,
    image: "/img13.png",
    title: "Post #3",
    content: `Feeling fancy with just a flick of a fan 📿<br><br>The floral patterns in red white, and gold give it a nostalgic feel that’s hard to find 💫  I loved playing with the colors and materials, especially the of turquoise at the base 🎨<br><br>#fashionaccessories #artisticjewelry #handmadeelegance`,
  },
  {
    id: 14,
    image: "/img14.png",
    title: "Post #3",
    content: `Look at this fancy plate pretending to be a piece of art 🎨<br><br>High-fired japanese stoneware platter. It's got this textured wave thing going on with earthy colors doing their best to look all natural and artistic. Perfect for anyone who wants their dinnerware to have more personality than their guests. 🌊<br><br>#ceramicart #potterylove #handmadepottery`,
  },
  {
    id: 15,
    image: "/img15.png",
    title: "Post #3",
    content: `Pour yourself a moment of calm with my traditional Japanese sake set<br><br>Its textured surface and wavy set it apart from the usual designs. The muted gray with subtle brown accents adds the rustic charm of a warm hug on a chilly evening<br><br>#sakeset #japanesetradition #zenlifestyle #handcrafteddesign #rusticcharm`,
  },
];

export default function Examples() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliding, setSliding] = useState(false);
  const [slideDirection, setSlideDirection] = useState(0);
  const timeoutRef = useRef(null);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState("auto");

  const debounce = (func, delay) => {
    return (...args) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => func(...args), delay);
    };
  };

  const handleTransition = useCallback(
    debounce((direction) => {
      setSliding(true);
      setSlideDirection(direction);

      setTimeout(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + direction + posts.length) % posts.length
        );
        setSliding(false);
        setSlideDirection(0);
      }, 300);
    }, 300),
    []
  );

  const handlePrev = () => handleTransition(-1);
  const handleNext = () => handleTransition(1);

  const getPostIndex = (shift) => {
    return (currentIndex + shift + posts.length) % posts.length;
  };

  useEffect(() => {
    const updateHeight = () => {
      if (window.innerWidth < 768 && contentRef.current) {
        const height = contentRef.current.offsetHeight;
        setContentHeight(`${height}px`);
      } else {
        setContentHeight("auto");
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [currentIndex]);

  return (
    <div className="w-full flex flex-col bg-[#F5F5FF] py-8 md:py-16">
      <h2 className="text-2xl md:text-3xl leading-[36px] font-bold bg-gradient-to-r from-[#2559EC] to-[#7388F7] bg-clip-text text-transparent text-center mb-6 md:mb-8">
        Examples
      </h2>
      <div
        className="relative mx-auto px-4 w-full"
        style={{ height: contentHeight }}
      >
        <div className="flex items-center justify-center h-full min-h-[445px]">
          {[-1, 0, 1].map((shift) => {
            const post = posts[getPostIndex(shift)];
            return (
              <div
                key={`${post.id}-${shift}`}
                className={`transition-all duration-300 ease-in-out absolute p-4 md:p-8 
                  ${
                    shift === 0
                      ? "z-20 opacity-100"
                      : "z-10 opacity-50 hidden lg:block"
                  }
                  w-[90%] md:w-[calc(80%-20px)] lg:w-[calc(60%-200px)]`}
                style={{
                  transform: `translateX(${(shift - slideDirection) * 103}%)`,
                }}
                ref={shift === 0 ? contentRef : null}
              >
                <div className="bg-white rounded-xl shadow-lg overflow-hidden h-full">
                  <div className="flex flex-col md:flex-row md:justify-center h-full">
                    <div className="w-full md:w-auto flex justify-center items-center p-4 md:p-5">
                      <img
                        src={post.image}
                        alt="Post"
                        className="w-full h-auto max-h-[286px] object-cover rounded-xl"
                      />
                    </div>
                    <div className="w-full md:flex-1 flex flex-col justify-center p-4 pr-10">
                      <p
                        className="text-lg md:text-xl text-start text-gray-600"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 11,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        dangerouslySetInnerHTML={{ __html: post.content }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <button
          onClick={handlePrev}
          className="absolute left-4 md:left-[20%] top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg z-30"
          disabled={sliding}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          onClick={handleNext}
          className="absolute right-4 md:right-[20%] top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg z-30"
          disabled={sliding}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
