import React from 'react'

function WhoWeAre() {
  return (
    <div id='About' className='flex flex-col md:flex-row px-4 gap-12 md:px-[15%] bg-white pb-24 items-center text-left'>
        
        <div className='md:mr-5'>
          <p className='text-3xl md:text-[40px] leading-[55px] font-semibold  bg-gradient-to-r from-[#2559EC] to-[#7388F7] bg-clip-text text-transparent mb-4'>Who We Are</p>
          <p className='text-justify mt-4'>SyncTank is a leader in AI-driven solutions, helping businesses enhance client interactions and operational efficiency. With a focus on innovation and customer satisfaction, SyncTank has become a trusted partner for companies looking to leverage AI for a competitive edge.</p>
          <a href="https://synctankai.com" target="_blank" rel="noopener noreferrer">
            <button className='h-[52px] bg-[#2559EC] rounded-full px-8 text-white mt-5'> 
              Explore SyncTankAI
            </button>
          </a>

        </div>
        <img src="frame9.png" alt="" className='max-w-full md:max-w-[50%] mb-8 md:mb-0' />
      </div>
  )
}

export default WhoWeAre
